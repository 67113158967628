import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { HttpStatus } from 'config/Api.Config';
import { first, isEmpty, set } from 'lodash';
import claimSaveSimpleApi from 'models/claims/claim/saveSimple/Api.Claim.SaveSimple';
import claimDocumentSaveApi from 'models/claims/claimDocument/save/Api.ClaimDocument.Save';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import Contract from 'models/sales/contract/Model.Contract';
import { useEffect, useMemo } from 'react';
import { claimEditIndexRoute } from '../../claimEdit/ClaimEdit.Index';
import claimJobDocumentSaveApi from 'models/claims/claimJobDocument/save/Api.ClaimJobDocument.Save';
import claimFindApi from 'models/claims/claim/find/Api.Claim.Find';

export default function useClaimSimpleClaim(props: { contract: Contract; onClose: () => void }) {
  const { lang } = getLocales();
  const { user, userId, dealerId } = useAuthStore();
  const dealer = user?.dealers?.find((d) => d.id === dealerId);

  const claimTypesApi = useApi({
    action: ClaimType.list,
    body: { productId: [props.contract.productId] },
  });
  const claimTypes = claimTypesApi?.payload?.data;

  const { redirect: redirectToClaim } = usePageRouter<Module.Claims.Claim.Params, Module.Claims.Claim.Query>({
    route: claimEditIndexRoute,
  });

  const form = useForm<Api.Core.Claim.SaveSimple.IRequest>({
    default: {
      submitedDateUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
      statusCode: ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_SELLING_DEALER,
      contract: props.contract,
      contractId: props.contract?.id,
      ownerId: userId,
      reporterId: userId,
      dealerId: dealerId,
      claimDocuments: [],
      claimJobs: [
        {
          jobNumber: 1,
          claimTypeId: undefined,
          cause: undefined,
          customerComplaint: undefined,
          correction: undefined,
          ownerId: userId,
          dataPoints: [],
        },
      ],
    },
    onSubmit: async (data) => {
      const claimTypeWithReport = claimTypes?.find(
        (it) => it.product?.id === (data.contract?.product?.id || data.contract?.productId)
      );

      const finalClaimJob = first(data?.claimJobs);
      const claimJobDocuments = [...(finalClaimJob?.claimJobDocuments || [])];
      delete finalClaimJob.claimJobDocuments;

      const claimResponse = await claimSaveSimpleApi({
        ...data,
        contractId: data.contract?.id,
        contract: { id: data.contract?.id },
        createdByPayee: {
          name: dealer?.name ?? `${user?.firstName} ${user?.lastName}`,
          email: dealer?.primaryEmailAddress ?? user?.email,
          phoneNumber: dealer?.primaryPhoneNumber,
          payeeTypeId: PayeeType.DEALER_ID,
        },
        claimJobs: !claimTypeWithReport?.claimTypeReportSettings?.length
          ? [
              {
                ...finalClaimJob,
              },
            ]
          : [
              {
                claimType: { id: claimTypeWithReport.id },
                ...finalClaimJob,
              },
            ],
        contactEmail: data.contract?.client?.email,
        contactPhone: data.contract?.client?.phone,
        streetAddress: data.contract?.client?.streetAddress,
        city: data.contract?.client?.city,
        postalCode: data.contract?.client?.postalCode,
        countryCode: data.contract?.client?.countryCode,
        provinceCode: data.contract?.client?.provinceCode,
      });

      const newClaimId = claimResponse.payload;
      const { payload: refetchedClaim } = await claimFindApi({ id: newClaimId });
      if (refetchedClaim && claimResponse.status === HttpStatus.ok) {
        for (const document of data.claimDocuments) {
          document.claimId = refetchedClaim?.id;
          await claimDocumentSaveApi(document);
        }

        if (!isEmpty(claimJobDocuments) && !isEmpty(refetchedClaim?.claimJobs)) {
          const claimJob = first(refetchedClaim?.claimJobs);
          for (const claimJobDoc of claimJobDocuments) {
            for (const file of claimJobDoc.formFiles) {
              if (file) await claimJobDocumentSaveApi({ ...claimJobDoc, formFile: file, claimJobId: claimJob.id });
            }
          }
        }
      }

      addLog({ success: claimResponse.message || lang.saveSuccess });
      redirectToClaim({ claimId: refetchedClaim?.id?.toString() });
      form.reload();
    },
    validation: (data, errors) => {
      if (!data.claimDocuments) set(errors, 'claimDocuments._objectError', lang.required);
      if (!data.contract) set(errors, 'contract._objectError', lang.required);
      if (first(data?.claimJobs)?.dataPoints?.some((it) => it.isRequired && !it.value))
        set(errors, 'claimJobs._objectError', lang.required);
    },
  });

  //#region Claim Types
  useEffect(() => {
    if (props.contract.productId) claimTypesApi.execute({ productId: [props.contract.productId] });
  }, [props.contract.productId]);

  const onlyClaimType = claimTypes?.length === 1;
  useEffect(() => {
    if (onlyClaimType) {
      form.update({ claimJobs: [{ claimTypeId: first(claimTypes)?.id }] });
    }
  }, [claimTypes]);

  const claimTypeValue = onlyClaimType ? first(claimTypes)?.id : first(form.data?.claimJobs)?.claimTypeId;

  const uploadableDocuments = useMemo(
    () => claimTypes?.find((it) => it.id === claimTypeValue)?.claimTypeDocuments ?? [],
    [claimTypeValue]
  );
  //#endregion

  return {
    form,
    claimTypes,
    onlyClaimType,
    claimTypeValue,
    uploadableDocuments,
  };
}
