import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { defaultOffset } from 'config/Api.Config';
import { Dictionary, groupBy, isEmpty, set } from 'lodash';
import ClaimJob from 'models/claims/claimJob/Model.ClaimJob';
import claimJobSaveApi from 'models/claims/claimJob/save/Api.ClaimJob.Save';
import ClaimJobComment from 'models/claims/claimJobComment/ClaimJobComment';
import claimJobCommentDeleteApi from 'models/claims/claimJobComment/delete/Api.ClaimJobComment.Delete';
import claimJobCommentSaveApi from 'models/claims/claimJobComment/save/Api.ClaimJobComment.Save';
import ClaimJobItem from 'models/claims/claimJobItem/Model.ClaimJobItem';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import LossCode from 'models/claims/lossCode/Model.LossCode';
import Payee from 'models/claims/payee/Model.Payee';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import CountryCode from 'models/enums/CountryCode';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import ProductType from 'models/enums/ProductType';
import { useEffect, useMemo, useState } from 'react';
import { useClaimEdit } from '../../ClaimEdit.Hooks';

export enum ClaimJobAction {
  EDIT = 'edit',
  DELETE = 'delete',
}

export type UseClaimJobProps = {
  claimJobId?: number;
};

export type ClaimJobTableProps = {
  items: Array<{ item: Utils.FormData<Model.IClaimJobItem>; index: number }>;
  updateJobItem(data: Utils.FormData<Model.IClaimJobItem>, index?: number): void;
  removeJobItem(data: Utils.FormData<Model.IClaimJobItem>, index: number): void;
  lossCodes: Array<LossCode>;
  currency: string;
  isWarrantyProduct?: boolean;
  isAddDisabled?: boolean;
  setNewPayeeId?: React.Dispatch<React.SetStateAction<number>>;
};

export default function useClaimJobEditCore({ claimJobId }: UseClaimJobProps) {
  const { permissions, userId } = useAuthStore();
  const {
    claim,
    emailTemplates,
    statusInfo,
    reloadClaim,
    product,
    contract,
    isClaimAdmin,
    isClaimOwner,
    isEditable,
    isClaimJobEditableByStatus,
    isClaimJobItemEditable,
  } = useClaimEdit();

  const claimTypesApi = useApi({ action: ClaimType.list, body: { productId: [product?.id] } });
  const claimTypes = claimTypesApi.payload?.data;

  const { lang } = getLocales();
  const [newPayeeId, setNewPayeeId] = useState<number>(null);

  const defaultStatusCode = userId
    ? ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_ASC
    : ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_CUSTOMER;

  const claimId = claim.id;
  const isWarrantyProduct = product?.productType === ProductType.warrantiClassBased;
  const isClaimJobEditable = isClaimAdmin || (isClaimOwner && isEditable);
  //#region Api calls

  const {
    payload: claimJob,
    isExecuting: isClaimJobExecuting,
    execute: reloadClaimJob,
  } = useApi(
    {
      action: ClaimJob.find,
      body: { id: claimJobId },
      default: null,
      wait: !claimJobId,
    },
    [claimJobId]
  );

  const {
    payload: comments,
    isExecuting: isLoadingComments,
    execute: reloadComments,
  } = useApi(
    {
      action: ClaimJobComment.list,
      body: { claimJobId },
      wait: !claimJobId,
      keepResponse: true,
    },
    [claimJobId]
  );

  function reload() {
    reloadClaimJob((body) => body);
    reloadComments((body) => body);
  }
  //#endregion

  //#region Data editing
  const [action, setAction] = useState<'edit' | 'delete' | 'expanded' | 'compressed'>();
  const form = useForm<Utils.FormData<Model.IClaimJob> & { emailTemplate?: Model.IClaimEmailTemplate }>(
    {
      default: {
        ...claimJob,
        claimId: claimJob?.claimId ?? claim?.id,
        jobNumber: claimJob?.jobNumber || (claim?.claimJobs?.length || 0) + 1,
        statusCode: claimJob?.statusCode || defaultStatusCode,
        ownerId: !claimJob?.id ? userId : claimJob?.ownerId,
      },
      onSubmit: async ({ emailTemplate, statusCode: status, ownerId, ...formJob }) => {
        const finalJob = {
          ...formJob,
          ownerId: claimJob?.ownerId,
          statusCode: claimJob?.statusCode || defaultStatusCode,
          claimJobItems: formJob?.claimJobItems?.filter(
            (it) => it.claimJobItemPayee?.payeeId || it.claimJobItemPayee?.dealerId || it.claimJobItemPayee?.clientId
          ),
        };
        const jobResponse = await claimJobSaveApi(finalJob);
        const savedClaimJobId = finalJob?.id || jobResponse.payload;
        const hasStatusChanged = status && status !== finalJob.statusCode;
        const hasOwnerChanged = ownerId && ownerId !== finalJob?.ownerId;
        if (hasStatusChanged && savedClaimJobId && ownerId)
          await ClaimJob.setStatus({ claimId, claimJobId: savedClaimJobId, status, ownerId, emailTemplate });
        else if (hasOwnerChanged && savedClaimJobId)
          await ClaimJob.changeOwnership({ claimId, claimJobId: savedClaimJobId, ownerId });

        if (savedClaimJobId) {
          if (!claimJobId) {
            reloadClaim();
            form.reload();
          } else {
            reloadClaimJob({ id: claimJobId });
          }
        }
        setAction('expanded');
      },
      validation: (data, errors) => {
        //TODO check if all documents are valid
        if (data.emailTemplate && !data.emailTemplate.name)
          set(errors, 'emailTemplate.name', `${lang.mustNotBeEmpty}: ${lang.name}`);
        if (data.emailTemplate && !data.emailTemplate.senderEmail)
          set(errors, 'emailTemplate.senderEmail', `${lang.mustNotBeEmpty}: ${lang.senderEmail}`);
        if (data.emailTemplate && !data.emailTemplate.senderName)
          set(errors, 'emailTemplate.senderName', `${lang.mustNotBeEmpty}: ${lang.senderName}`);
        if (data.emailTemplate && !data.emailTemplate.htmlBody)
          set(errors, 'emailTemplate.htmlBody', `${lang.mustNotBeEmpty}: ${lang.htmlBody}`);
        // Claim jobs
        if (!data.claimType?.id) set(errors, 'claimType.id', lang.mustNotBeEmpty);
        if (
          data.claimJobItems?.some(
            (it) => it.hoursWorked === null || (it.hoursWorked < 0 && it.jobItemTypeCode === LineItem.labour)
          )
        )
          set(errors, 'claimJobItems.hoursWorked', lang.mustBeMoreThanAmount.replace('{amount}', '0'));
        if (data.claimJobItems?.some((it) => !it.claimJobItemPayee && it.jobItemTypeCode === LineItem.deductible))
          set(errors, 'claimJobItems.claimJobItemPayee', lang.mustNotBeEmpty);
        if (data.claimJobItems?.some((it) => it.gstHst > 1))
          set(
            errors,
            'claimJobItems.gstHst',
            lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt amount`)
          );
        if (data.claimJobItems?.some((it) => it.provincialSalesTax > 1))
          set(
            errors,
            'claimJobItems.provincialSalesTax',
            lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt amount`)
          );
      },
    },
    [claimJob, claim]
  );

  function isSamePayee(
    payee1?: Utils.FormData<Model.IClaimJobItemPayee>,
    payee2?: Utils.FormData<Model.IClaimJobItemPayee>
  ) {
    return payee1?.clientId
      ? payee1?.clientId === payee2?.clientId
      : payee1?.dealerId
      ? payee1?.dealerId === payee2?.dealerId
      : payee1?.payeeId
      ? payee1?.payeeId === payee2?.payeeId
      : false;
  }
  function updateJobItem(data?: Utils.FormData<Model.IClaimJobItem>, index?: number) {
    let jobItem = new ClaimJobItem(data ?? {}).toJSON();
    const claimJobItems = form.data?.claimJobItems || [];
    const currencyKey = jobItem?.currency === 'CAD' ? 'CAD' : 'USD';

    const groupedClaimJobItems: Dictionary<Utils.FormData<Model.IClaimJobItem>[]> = groupBy(
      claimJobItems,
      (it) => it.currency
    );
    const targetClaimJobItems = groupedClaimJobItems[currencyKey] || [];
    for (const claimJobItem of targetClaimJobItems) {
      if (!claimJobItem.paymentMethodCode) {
        claimJobItem.paymentMethodCode =
          targetClaimJobItems?.find(
            (it) => isSamePayee(claimJobItem.claimJobItemPayee, it.claimJobItemPayee) && it.paymentMethodCode
          )?.paymentMethodCode ??
          claimJobItem.claimJobItemPayee?.dealer?.paymentMethodCode ??
          claimJobItem.claimJobItemPayee?.payee?.paymentMethodCode;
      }
    }

    if (jobItem?.jobItemTypeCode === LineItem.labour) {
      const labourRate =
        jobItem?.labourRate === 0
          ? jobItem?.claimJobItemPayee?.payee?.labourRate ?? jobItem?.claimJobItemPayee?.dealer?.labourRate
          : jobItem?.labourRate;

      const correction = labourRate ? labourRate * jobItem?.hoursWorked : 0.0;
      jobItem = { ...jobItem, correction };
    }

    if (isValidValue(index)) {
      targetClaimJobItems[index] = jobItem;
    } else {
      targetClaimJobItems.push(jobItem);
    }

    groupedClaimJobItems[currencyKey] = targetClaimJobItems;

    const mergedItems = Object.values(groupedClaimJobItems).flat();

    form.update({ claimJobItems: mergedItems });
  }
  function updateMultipleJobItems(
    claimJobItemPayee: Utils.FormData<Model.IClaimJobItemPayee>,
    data: Utils.FormData<Omit<Model.IClaimJobItem, 'claimJobItemPayee'>>
  ) {
    const claimJobItems = [];
    for (const claimJobItem of form.data?.claimJobItems || []) {
      if (isSamePayee(claimJobItemPayee, claimJobItem.claimJobItemPayee)) {
        claimJobItems.push({ ...claimJobItem, ...data });
      } else {
        claimJobItems.push(claimJobItem);
      }
    }
    form.update({ claimJobItems });
  }
  function removeJobItem(data: Utils.FormData<Model.IClaimJobItem>, index: number) {
    const currentGroupedClaimJobItems: Dictionary<Utils.FormData<Model.IClaimJobItem>[]> = groupBy(
      form.data?.claimJobItems || [],
      (it) => it.currency
    );

    const targetClaimJobItems = currentGroupedClaimJobItems[data.currency] || [];

    if (isValidValue(index)) {
      targetClaimJobItems.splice(index, 1);
    }

    const mergedItems = Object.values(currentGroupedClaimJobItems).flat();

    form.update({ claimJobItems: mergedItems });
  }
  function createDefaultJobItems() {
    const claimJobItems: Array<Utils.FormData<Model.IClaimJobItem>> = [];
    const labourItems = 1;
    const partItems = 3;

    const createJobItem = (jobItemTypeCode: LineItem, correction: number) =>
      new ClaimJobItem({
        jobItemTypeCode,
        statusCode: PaymentStatus.unpaid,
        currency: 'CAD',
        adjudication: 0.0,
        paymentMethodCode: undefined,
        correction,
      }).toJSON();

    if (isWarrantyProduct) {
      claimJobItems.push({
        ...createJobItem(LineItem.deductible, contract.deductible),
      });
    }

    for (let i = 0; i < labourItems; i++) {
      claimJobItems.push(createJobItem(LineItem.labour, 0.0));
    }

    for (let i = 0; i < partItems; i++) {
      claimJobItems.push(createJobItem(LineItem.part, 0.0));
    }

    form.update({ claimJobItems });
  }
  //#endregion

  //#region Variables
  const claimType = useMemo(
    () => claimTypes?.find((it) => it.id === form?.data?.claimType?.id),
    [claimTypes, form?.data?.claimType?.id]
  );
  const nextStatus = form.data.statusCode;
  const currentStatus = claimJob?.statusCode || defaultStatusCode;
  const isStatusChanged = nextStatus && nextStatus !== currentStatus;
  const isEditLoading = !claimTypes?.length || isClaimJobExecuting || !claimJob?.claimJobItems;
  const uploadedDocuments = form?.data?.claimJobDocuments?.filter((doc) => doc?.id) || [];
  const uploadableDocuments = useMemo(() => claimType?.claimTypeDocuments || [], [claimType]);
  //#endregion

  //#region Reports
  const claimTypeReport = useMemo(
    () => (claimType?.claimTypeReportSettings?.length ? claimType.claimTypeReportSettings[0] : null),
    [claimType]
  );
  const report = useMemo(() => {
    const firstReport = form.data?.claimJobReports && form.data?.claimJobReports[0];
    return claimTypeReport?.buildReportObject(firstReport?.productReport || {});
  }, [form.data, claimTypeReport]);
  //#endregion

  function getJobItemDefaultValues({
    jobItemTypeCode,
    claimJobItemPayee,
  }: {
    jobItemTypeCode?: string;
    claimJobItemPayee?: Utils.FormData<Model.IClaimJobItemPayee>;
  }) {
    const jobs = form.data?.claimJobItems ?? [];

    const payee = claimJobItemPayee?.dealer ?? claimJobItemPayee?.payee;
    const tax = product.productTaxes?.find((it) => {
      return it.provinceCode === (payee?.provinceCode || claimJobItemPayee?.client);
    });
    const paymentMethodCode =
      jobs?.find((it) => isSamePayee(claimJobItemPayee, it.claimJobItemPayee) && it.paymentMethodCode)
        ?.paymentMethodCode ?? payee?.paymentMethodCode;

    return {
      correction: jobItemTypeCode === LineItem.deductible && isWarrantyProduct ? contract?.deductible : 0.0,
      gstHst: tax?.claimsGstHst || 0.0,
      provincialSalesTax: tax?.claimsProvincialSalesTax || 0.0,
      labourRate: payee?.labourRate,
      paymentMethodCode: paymentMethodCode,
    };
  }

  useEffect(() => {
    if (claimJob && isEmpty(claimJob.claimJobItems)) {
      createDefaultJobItems();
    }
  }, [claimJob, isWarrantyProduct]);

  const isAnyClaimJobItemPaid = claimJob?.claimJobItems?.some((it) => it.statusCode === PaymentStatus.paid);

  const mapClaimJobDataPoints = (claimTypeDataPoints: Utils.FormData<Model.IClaimTypeDataPoint[]>) =>
    claimTypeDataPoints?.map((it) => ({
      claimTypeDataPointId: it.id,
      claimTypeId: it.claimTypeId,
      name: it.name,
      type: it.type,
      version: it.version,
      isRequired: it.isRequired,
      value: undefined,
    }));

  return {
    product,
    comments,
    claimJob,
    claimTypes,
    isLoadingComments,
    reloadComments,
    uploadedDocuments,
    uploadableDocuments,
    claim,
    claimTypeReport,
    form,
    claimType,
    currentStatus,
    isStatusChanged,
    updateJobItem,
    removeJobItem,
    reload,
    isEditable,
    permissions,
    isEditLoading,
    emailTemplates,
    statusInfo,
    action,
    setAction,
    isClaimAdmin,
    isClaimOwner,
    isClaimJobEditable,
    report,
    updateMultipleJobItems,
    getJobItemDefaultValues,
    isWarrantyProduct,
    reloadClaim,
    isClaimJobEditableByStatus,
    isClaimJobItemEditable,
    isAnyClaimJobItemPaid,
    defaultStatusCode,
    mapClaimJobDataPoints,
    newPayeeId,
    setNewPayeeId,
  };
}

const { useProvider: useClaimJobEdit, Provider: ClaimJobEditProvider } = ProviderBuilder(useClaimJobEditCore);

export { ClaimJobEditProvider, useClaimJobEdit };

export function useClaimEditJobComments() {
  const {
    form: jobForm,
    reloadComments,
    comments,
    isLoadingComments,
    isClaimJobEditable,
    isClaimJobEditableByStatus,
  } = useClaimJobEdit();
  const { lang } = getLocales();
  const auth = useAuthStore();
  const [action, setAction] = useState<'edit' | 'delete' | 'expanded' | 'compressed'>();
  const form = useForm<Utils.FormData<Model.IClaimJobComment>>({
    default: {
      claimJobId: jobForm.data.id,
      userId: auth.userId,
      comment: '',
    },
    validation: (data, errors) => {
      if (!data.comment) errors.comment = lang.required;
    },
    onSubmit: async (data) => {
      const response = await claimJobCommentSaveApi({
        ...data,
        modifiedAtUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
      });
      if (!response) addLog({ error: lang.unknownError });
      else {
        reloadComments((body) => body);
      }
    },
  });
  useEffect(() => {
    if (form.isSubmitted) {
      setAction(null);
      form.reload();
    }
  }, [form.isSubmitted]);

  useEffect(() => {
    if (jobForm.data.id)
      reloadComments({
        claimJobId: jobForm.data.id,
      });
    setAction(null);
    form.reload();
  }, [jobForm.data.id]);

  const deleteApi = useApi({
    action: claimJobCommentDeleteApi,
    callback: () => {
      setAction(null);
      reloadComments((body) => body);
    },
    wait: true,
  });
  return {
    hasJob: !!jobForm.data.id,
    form,
    deleteApi,
    isLoadingComments,
    comments,
    action,
    setAction,
    isClaimJobEditable,
    isClaimJobEditableByStatus,
  };
}

export function useClaimEditJobPayeeItemCore() {
  const { contract, permissions } = useClaimEdit();
  const defaultPayeeLimit = 5;

  const [search, setSearch] = useState<string>(undefined);
  const [offset, setOffset] = useState<number>(defaultOffset);
  const [limit, setLimit] = useState<number>(defaultPayeeLimit);

  const payeeTypeApi = useApi({ action: PayeeType.list });
  const payeApi = useApi({ action: Payee.list, wait: true });

  useEffect(() => {
    payeApi.execute({
      ...(search ? { search } : undefined),
      countryCode: CountryCode.CAN,
      limit,
      offset,
    });
  }, [limit, offset, search]);

  const payeeList = useMemo(() => {
    const data: ClaimJobItemPayee[] = [];
    if (contract?.client && offset === 0) {
      data.push(
        new ClaimJobItemPayee({
          client: contract.client,
          clientId: contract.client?.id,
          payeeType: payeeTypeApi.payload?.data?.find((it) => it.id === PayeeType.CLIENT_ID),
        })
      );
    }
    if (contract?.dealer && offset === 0) {
      data.push(
        new ClaimJobItemPayee({
          dealer: contract.dealer,
          dealerId: contract.dealer?.id,
          payeeType: payeeTypeApi.payload?.data?.find((it) => it.id === PayeeType.DEALER_ID),
        })
      );
    }

    data.push(
      ...(payeApi?.payload?.data?.map(
        (payee) =>
          new ClaimJobItemPayee({
            payee,
            payeeId: payee?.id,
            payeeType: payeeTypeApi.payload?.data?.find((it) => it.id === payee?.payeeTypeId),
          })
      ) || [])
    );
    return data;
  }, [offset === 0, payeApi?.payload?.data, contract?.dealer?.id, contract?.client?.id, payeeTypeApi?.payload?.data]);
  return {
    setSearch,
    search,
    setOffset,
    setLimit,
    offset,
    payeeList,
    count: payeApi.payload?.count ?? 0,
    limit,
    payeeTypeApi,
    permissions,
  };
}

const { useProvider: useClaimEditJobPayeeItem, Provider: ClaimEditJobPayeeItemProvider } =
  ProviderBuilder(useClaimEditJobPayeeItemCore);
export { ClaimEditJobPayeeItemProvider, useClaimEditJobPayeeItem };
