import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { cleanValues } from 'assets/utils/data/Object';
import {
  compact,
  differenceBy,
  first,
  flatten,
  intersection,
  intersectionBy,
  pick,
  reduce,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import PricingLimitTemplate from 'models/productManagement/pricingLimitTemplate/Model.PricingLimitTemplate';
import Product from 'models/productManagement/product/Model.Product';
import ProductPricingPlan from 'models/productManagement/productPricingPlan/Model.ProductPricingPlan';
import productPricingPlanClassSaveApi from 'models/productManagement/productPricingPlanClass/save/Api.ProductPricingPlanClass.Save';
import VehicleClass from 'models/productManagement/vehicleClass/Model.VehicleClass';
import { useEffect, useMemo, useState } from 'react';
import { warrantyClassPricingRoute } from './WarrantyClassPricing.Index';
import productPricingPlanSaveApi from 'models/productManagement/productPricingPlan/save/Api.ProductPricingPlan.Save';
import productPricingPlanClassDeleteApi from 'models/productManagement/productPricingPlanClass/delete/Api.ProductPricingPlanClass.Delete';
import useDistributorPricingDistributions from 'module/productManagement/hooks/useDistributorPricingDistributions';
import useDealerPricingSettings from 'module/productManagement/hooks/useDealerPricingSettings';
import ProductPricingPlanClass from 'models/productManagement/productPricingPlanClass/Model.ProductPricingPlanClass';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { ProgressKey } from 'assets/components/progressWindow/ProgressWindow';

export type Limits = {
  term: number;
  maxKm: number;
};
type PricePoint = Model.IProductPricingPlanClass & {
  isUpdated?: boolean;
};

export default function useWarrantyClassPricing() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.WarrantyClassPricing.Params,
    Module.ProductManagement.WarrantyClassPricing.Query
  >({
    route: warrantyClassPricingRoute,
  });
  const productId = parseInt(pageRouter.params.productId) || 0;
  const productProvinceGroupId = parseInt(pageRouter.params.productProvinceGroupId) || undefined;
  const hasAccess =
    permissions.CREATE_PRODUCTPRICINGPLAN ||
    permissions.EDIT_PRODUCTPRICINGPLAN ||
    permissions.DELETE_PRODUCTPRICINGPLAN;

  //#region APIS
  const listApi = useApi(
    {
      action: ProductPricingPlan.list,
      body: { productId: [productId] },
      wait: !hasAccess,
    },
    [productId, hasAccess]
  );
  const pricesApi = useApi(
    {
      action: ProductPricingPlanClass.list,
      body: { productId: [productId], provinceGroupId: productProvinceGroupId ? [productProvinceGroupId] : undefined },
      wait: !hasAccess,
    },
    [hasAccess, productId, productProvinceGroupId]
  );

  const vehicleClassApi = useApi({
    action: VehicleClass.list,
    wait: !hasAccess,
  });
  const limitTemplatesApi = useApi({
    action: PricingLimitTemplate.list,
    wait: !hasAccess,
  });

  const productApi = useApi({
    action: Product.find,
    body: { id: productId },
    wait: !hasAccess,
  });
  //#endregion

  const pricingPlans = useMemo(
    () =>
      sortBy(
        listApi.payload?.data?.filter((it) => it.productProvinceGroupId == productProvinceGroupId) || [],
        (it) => it.minOdometerKm,
        (it) => it.maxOdometerKm,
        (it) => it.name
      ),
    [listApi.payload?.data, productProvinceGroupId]
  );

  const distributorPricingDistributions = useDistributorPricingDistributions({
    productId,
  });

  const dealerPricingSettings = useDealerPricingSettings({
    productId,
  });

  const [editedId, setEditableId] = useState<number | null>(null);
  const selected = useMemo(() => {
    return pricingPlans.find((it) => it.id.toString() === editedId?.toString());
  }, [pricingPlans, editedId]);

  //#region PRICES
  const [data, setData] = useState<PricePoint[]>([]);
  const isUpdated = useMemo(
    () =>
      data?.some((it) => it.isUpdated) ||
      distributorPricingDistributions.distributorData?.isUpdated ||
      distributorPricingDistributions.distributorData?.distributions?.some((it) => it.isUpdated) ||
      (dealerPricingSettings.isUpdated && !dealerPricingSettings.hasErrors),
    [
      data,
      distributorPricingDistributions.distributorData,
      dealerPricingSettings.isUpdated,
      dealerPricingSettings.hasErrors,
    ]
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setData(() =>
      flatten(pricesApi?.payload?.data?.map((it) => it.toJSON()) || []).map((it) => ({
        ...it,
        deductible: it.deductible || 0,
      }))
    );
  }, [pricesApi?.payload?.data]);

  async function saveAll(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    setIsSaving(true);
    distributorPricingDistributions.saveAll(onSaveProgress);
    dealerPricingSettings.saveAll(onSaveProgress);
    const updatedPricesCount = await iterateWithProgress(
      data.filter((it) => it.isUpdated),
      async (pricePoint) => {
        if (pricePoint.priceCAD == null && pricePoint.id) {
          await productPricingPlanClassDeleteApi(pricePoint.id);
        } else if (pricePoint.priceCAD != null) {
          await productPricingPlanClassSaveApi(cleanValues(pricePoint));
        }
      },
      (p) => onSaveProgress('priceDistribution', p)
    );
    if (updatedPricesCount) {
      await listApi.execute((body) => body);
      await pricesApi.execute((body) => body);
    }
    setIsSaving(false);
  }

  function getPrice(
    limits: Limits,
    productPricingPlanId: number,
    vehicleClassId: number,
    deductible?: number,
    customInfo?: string
  ) {
    return data.find(
      (it) =>
        it.term === limits.term &&
        it.maxKm === limits.maxKm &&
        it.productPricingPlanId === productPricingPlanId &&
        it.vehicleClassId === vehicleClassId &&
        it.deductible == deductible &&
        it.customInfo === customInfo
    );
  }
  function updatePrice(
    limits: Limits,
    productPricingPlanId: number,
    vehicleClassId: number,
    deductible?: number,
    customInfo?: string,
    pricingDistributions?: Partial<Utils.PricingDistribution>
  ) {
    const d = getPrice(limits, productPricingPlanId, vehicleClassId, deductible, customInfo);
    if (d) {
      d.isUpdated = true;
      if (pricingDistributions === null) {
        d.priceCAD = null;
        d.pricingDistributions = null;
        d.subfees = null;
      } else {
        d.pricingDistributions = {
          ...productApi?.payload?.pricingDistribution,
          ...d.pricingDistributions,
          ...pricingDistributions,
        };
        d.priceCAD = reduce(d.pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0);
      }
      setData((old) => [...old]);
    } else {
      const newD: PricePoint = {
        id: undefined,
        isUpdated: true,
        ...limits,
        priceCAD: reduce(pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0),
        pricingDistributions,
        productPricingPlanId,
        vehicleClassId,
        deductible,
        customInfo,
      };
      setData((old) => [...old, newD]);
    }
  }
  function updateSubfee(
    limits: Limits,
    productPricingPlanId: number,
    vehicleClassId: number,
    deductible?: number,
    customInfo?: string,
    subfees?: Utils.SurchargeInfo
  ) {
    const d = getPrice(limits, productPricingPlanId, vehicleClassId, deductible, customInfo);
    if (d) {
      d.isUpdated = true;
      d.subfees = uniqBy(compact([subfees, ...(d.subfees ?? [])]), (it) => it.subfeeId);
      setData((old) => [...old]);
    }
  }
  function updateOriginalPrice({
    limit,
    pricingPlan,
    vehicleClass,
    deductible,
    customInfo,
    distributionKey,
    distributedValue,
  }: {
    limit: Limits;
    pricingPlan: Model.IProductPricingPlan;
    vehicleClass: Model.IVehicleClass;
    deductible?: number;
    customInfo?: string;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const originalDistributions = getPrice(
      limit,
      pricingPlan.id,
      vehicleClass.id,
      deductible,
      customInfo
    )?.pricingDistributions;
    if (originalDistributions) {
      updatePrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo, {
        ...originalDistributions,
        [distributionKey]: distributedValue,
      });
    }
  }
  function updateDistributorPrice({
    limit,
    pricingPlan,
    vehicleClass,
    deductible,
    customInfo,
    distributionKey,
    distributedValue,
  }: {
    limit: Limits;
    pricingPlan: Model.IProductPricingPlan;
    vehicleClass: Model.IVehicleClass;
    deductible?: number;
    customInfo?: string;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const currentPricePoint = getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo);
    if (currentPricePoint?.pricingDistributions) {
      const currentDistribution = distributorPricingDistributions.getDistributorPrice(
        currentPricePoint?.id,
        'ProductPricingPlanClass'
      )?.distributions;
      distributorPricingDistributions.updateDistributorPrice({
        pricePointId: currentPricePoint.id,
        pricePointType: 'ProductPricingPlanClass',
        distributions: {
          ...currentDistribution,
          [distributionKey]: distributedValue,
        },
      });
    }
  }
  function addEmptyPrices(
    newVehicleClasses: VehicleClass[],
    newLimits: Limits[],
    newDeductibles: number[],
    newCustomInfos: string[]
  ) {
    const prices: PricePoint[] = [];
    for (const productPricingPlan of pricingPlans) {
      for (const limit of newLimits) {
        for (const vehicleClass of newVehicleClasses) {
          for (const deductible of newDeductibles) {
            for (const customInfo of newCustomInfos) {
              if (
                !data.some(
                  (it) =>
                    it.vehicleClassId === vehicleClass.id &&
                    it.term === limit.term &&
                    it.maxKm === limit.maxKm &&
                    it.deductible == deductible &&
                    it.customInfo === customInfo
                )
              ) {
                prices.push({
                  id: undefined,
                  isUpdated: false,
                  term: limit.term,
                  maxKm: limit.maxKm,
                  priceCAD: null,
                  productPricingPlanId: productPricingPlan?.id,
                  vehicleClassId: vehicleClass.id,
                  deductible,
                  customInfo,
                });
              }
            }
          }
        }
      }
    }

    setData((old) => [...old, ...prices]);
  }
  //#endregion

  const deductibles = useMemo(() => sortBy(uniq(data.map((d) => d.deductible || 0).filter(isValidValue))), [data]);

  function removeDeductible(deductible: number) {
    setData((prices) => prices.filter((it) => it.deductible !== deductible));
  }

  const customInfos = useMemo(() => sortBy(uniq(data.map((d) => d.customInfo).filter(isValidValue))), [data]);

  function removeCustomInfo(customInfo: string) {
    setData((prices) => prices.filter((it) => it.customInfo !== customInfo));
  }

  function updateCustomInfo(oldCustomInfo: string, newCustomInfo: string) {
    setData((oldPrices) =>
      oldPrices.map((it) => {
        if (it.customInfo === oldCustomInfo && isValidValue(newCustomInfo)) {
          return { ...it, customInfo: newCustomInfo, isUpdated: true };
        }
        return it;
      })
    );
  }

  const vehicleClasses = useMemo(
    () =>
      sortBy(
        uniqBy(
          compact(data.map((d) => vehicleClassApi.payload?.data?.find((vc) => vc?.id === d.vehicleClassId))),
          (it) => it.id
        ),
        (it) => it.primaryCode,
        (it) => it.secondaryCode
      ),
    [data, vehicleClassApi.payload?.data]
  );

  const unselectedVehicleClasses = useMemo(
    () => differenceBy(vehicleClassApi.payload?.data || [], vehicleClasses, (it) => it.id),
    [vehicleClassApi.payload?.data, vehicleClasses]
  );

  function removeVehicleClass(vehicleClass: Model.IVehicleClass) {
    setData((prices) => prices.filter((it) => it.vehicleClassId !== vehicleClass.id));
  }

  const limits = useMemo(
    () =>
      sortBy(
        uniqBy(
          compact(
            data.map((d) => limitTemplatesApi.payload?.data?.find((t) => t.maxKm === d.maxKm && t.term === d.term))
          ).map((it) => pick(it, 'maxKm', 'term')),
          (it) => `${it.term} ${it.maxKm}`
        ),
        (it) => `${it.term} ${it.maxKm}`
      ),
    [data, limitTemplatesApi.payload?.data]
  );
  function removeLimits(limits: Limits) {
    setData((prices) => prices.filter((it) => !(it.maxKm === limits.maxKm && it.term === limits.term)));
  }
  const unselectedLimits = useMemo(
    () => differenceBy(limitTemplatesApi.payload?.data || [], limits, (it) => `${it.term} ${it.maxKm}`),
    [limitTemplatesApi.payload?.data, limits]
  );

  const isAllowedToCopy = !!productProvinceGroupId && data.length === 0 && pricingPlans.length === 0;
  async function copy() {
    setIsSaving(true);
    if (!listApi.payload?.data?.some((it) => it.productProvinceGroupId === productProvinceGroupId)) {
      const defaultPricingPlans = listApi.payload?.data?.filter((it) => !it.productProvinceGroupId);
      for (const defaultPricingPlan of defaultPricingPlans) {
        const newPricingPlan: Partial<Model.IProductPricingPlan> = {
          name: defaultPricingPlan.name,
          primaryDescription: defaultPricingPlan.primaryDescription,
          secondaryDescription: defaultPricingPlan.secondaryDescription,
          maxOdometerKm: defaultPricingPlan.maxOdometerKm,
          minOdometerKm: defaultPricingPlan.minOdometerKm,
          productId: defaultPricingPlan.productId,
          productProvinceGroupId,
        };
        const saveResponse = await productPricingPlanSaveApi(newPricingPlan);
        if (saveResponse.payload) {
          newPricingPlan.id = saveResponse.payload;
          for (const pricingPlanClass of pricesApi?.payload?.data || []) {
            await productPricingPlanClassSaveApi({
              productPricingPlanId: newPricingPlan.id,
              vehicleClassId: pricingPlanClass.vehicleClassId,
              term: pricingPlanClass.term,
              maxKm: pricingPlanClass.maxKm,
              priceCAD: pricingPlanClass.priceCAD,
              deductible: pricingPlanClass.deductible,
              customInfo: pricingPlanClass.customInfo,
              pricingDistributions: pricingPlanClass.pricingDistributions,
            });
          }
        }
      }
    }
    await listApi.execute((body) => body);
    setIsSaving(false);
  }

  const [selection, setSelection] = useState<{
    pricingPlans: typeof pricingPlans;
    limits: typeof limits;
    vehicleClasses: typeof vehicleClasses;
    deductibles: typeof deductibles;
  }>({
    pricingPlans: [],
    limits: [],
    vehicleClasses: [],
    deductibles: [],
  });
  useEffect(() => {
    if (!pricingPlans.length || !limits.length || !vehicleClasses.length || !deductibles.length) return;
    const newPricingPlans = intersectionBy(selection.pricingPlans, pricingPlans, (it) => it.id);
    const newLimits = intersectionBy(selection.limits, limits, (it) => `${it.maxKm}-${it.term}`);
    const newVehicleClasses = intersectionBy(selection.vehicleClasses, vehicleClasses, (it) => it.id);
    const newDeductibles = intersection(selection.deductibles, deductibles);

    setSelection({
      pricingPlans: !newPricingPlans.length ? compact([first(pricingPlans)]) : newPricingPlans,
      limits: !newLimits.length ? compact([first(limits)]) : newLimits,
      vehicleClasses: !newVehicleClasses.length ? compact([first(vehicleClasses)]) : newVehicleClasses,
      deductibles: !newDeductibles.length ? deductibles : newDeductibles,
    });
  }, [pricingPlans, limits, vehicleClasses, deductibles, customInfos]);

  return {
    distributorPricingDistributions,
    dealerPricingSettings,
    hasAccess,
    provinceGroup: productApi?.payload?.productProvinceGroups?.find((g) => g.id === productProvinceGroupId),
    isAllowedToCopy,
    copy,
    addEmptyPrices,
    removeLimits,
    removeDeductible,
    isSaving,
    removeVehicleClass,
    setEditableId,
    editedId,
    unselectedVehicleClasses,
    unselectedLimits,
    pricingPlans,
    selected,
    isUpdated,
    limits,
    deductibles,
    vehicleClasses,
    customInfos,
    removeCustomInfo,
    updatePrice,
    updateSubfee,
    updateDistributorPrice,
    updateOriginalPrice,
    updateCustomInfo,
    getPrice,
    data,
    saveAll,
    pageRouter,
    productApi,
    listApi,
    permissions,
    selection,
    setSelection,
    setData,
  };
}

export function useWarrantyClassPricingProvider() {
  return useDataProvider<ReturnType<typeof useWarrantyClassPricing>>();
}
