import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import ObjectInfo from 'assets/templates/objectInfo/ObjectInfo';
import { formatNumberWithSeparator } from 'assets/utils/data/Number';
import { isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { capitalize, isNull, mapKeys, mapValues, pick, values } from 'lodash';
import ContractConsentStatus from 'models/enums/ContractConsentStatus';
import PurchaseState from 'models/enums/PurchaseState';
import Subfee from 'models/productManagement/subfee/Model.Subfee';
import Contract from 'models/sales/contract/Model.Contract';

type Props = {
  contract: Partial<Model.IContract>;
};
export default function ContractInfoDetails({ contract }: Props) {
  const { lang } = getLocales();
  const { payload } = useApi({ action: Subfee.list });
  const { isDealerOrDistributor, isDealer } = useAuthStore();
  const subfees = payload?.data;
  if (!contract) return null;

  const taxExemptPrice = new Contract(contract).taxExemptPrices;

  return (
    <>
      <h3>{lang.prices}</h3>
      <div>
        {contract.product?.name || lang.unknownProduct}:{' '}
        <b>
          <FormatValue value={`${(contract.priceCAD || 0).toFixed(2)}$`} />
        </b>
      </div>
      {!isDealerOrDistributor && contract.originalPriceCAD !== contract.priceCAD && (
        <div>
          {lang.originalPrice}:{' '}
          <b>
            <FormatValue value={`${(contract.originalPriceCAD || 0).toFixed(2)}$`} />
          </b>
        </div>
      )}
      {!isDealerOrDistributor && !!contract.discountPriceCAD && (
        <div>
          {lang.adjustedPrice}:{' '}
          <b>
            <FormatValue value={`${(contract.discountPriceCAD || 0).toFixed(2)}$`} />
          </b>
        </div>
      )}
      {!isDealer && !!contract.distributorPriceCAD && (
        <div>
          {lang.distributorPrice}:{' '}
          <b>
            <FormatValue value={`${(contract.distributorPriceCAD || 0).toFixed(2)}$`} />
          </b>
        </div>
      )}
      <div>
        PST:{' '}
        <b>
          <FormatValue value={`${((contract.provincialSalesTax || 0) * 100).toFixed(2)}%`} />
        </b>
      </div>
      <div>
        GST/HST:{' '}
        <b>
          <FormatValue value={`${((contract.gstHst || 0) * 100).toFixed(2)}%`} />
        </b>
      </div>
      <h3>{lang.salePrice}</h3>
      <div>
        {lang.amount}:{' '}
        <b>
          <FormatValue value={`${(contract.salePriceCAD || 0).toFixed(2)}$`} />
        </b>
      </div>
      <div>
        {lang.tax}:{' '}
        <b>
          {contract.client?.taxExempt ? (
            `${lang.exempt.toUpperCase()}`
          ) : (
            <FormatValue value={`${(contract.totalTaxCAD || 0).toFixed(2)}$`} />
          )}
        </b>
      </div>
      <hr />
      <div>
        {lang.total}:{' '}
        <b>
          <FormatValue value={`${(taxExemptPrice.salePriceTotalCAD || 0).toFixed(2)}$`} />
        </b>
      </div>
      <h3>{lang.paymentPrice}</h3>
      <div>
        {lang.amount}:{' '}
        <b>
          <FormatValue value={`${(contract.paymentAmountCAD || 0).toFixed(2)}$`} />
        </b>
      </div>
      <div>
        {lang.tax}:{' '}
        <b>
          {contract.client?.taxExempt ? (
            `${lang.exempt.toUpperCase()}`
          ) : (
            <FormatValue value={`${(contract.paymentTaxCAD || 0).toFixed(2)}$`} />
          )}
        </b>
      </div>
      <hr />
      <div>
        {lang.total}:{' '}
        <b>
          <FormatValue value={`${(taxExemptPrice.paymentAmountTotalCAD || 0).toFixed(2)}$`} />
        </b>
      </div>
      <h3>{lang.paymentOptions}</h3>
      {contract.termInMonths && (
        <div>
          {lang.term}:{' '}
          <b>
            <FormatValue value={`${contract.termInMonths}m`} />
          </b>
        </div>
      )}
      {contract.maxKm && (
        <div>
          {lang.maxKm}:{' '}
          <b>
            <FormatValue value={`${contract.maxKm}km`} />
          </b>
        </div>
      )}
      {contract.coverageKm && (
        <div>
          {lang.coverageKm}:{' '}
          <b>
            <FormatValue value={`${contract.coverageKm}km`} />
          </b>
        </div>
      )}
      {contract.deductible && (
        <div>
          {lang.deductible}: <b>{contract.deductible}</b>
        </div>
      )}
      {contract.customInfo && (
        <div>
          {lang.customInfo}: <b>{contract.customInfo}</b>
        </div>
      )}
      {contract.customInfoGroup && (
        <div>
          {lang.customInfoGroup}: <b>{contract.customInfoGroup}</b>
        </div>
      )}
      {contract.minVehiclePriceCAD && (
        <div>
          {lang.minPrice}:{' '}
          <b>
            <FormatValue value={`${contract.minVehiclePriceCAD.toFixed(2)}$`} />
          </b>
        </div>
      )}
      {contract.maxVehiclePriceCAD && (
        <div>
          {lang.maxPrice}:{' '}
          <b>
            <FormatValue value={`${contract.maxVehiclePriceCAD.toFixed(2)}$`} />
          </b>
        </div>
      )}
      {contract.tier && (
        <div>
          {lang.tier}: <b>{contract.tier}</b>
        </div>
      )}
      {contract.class && (
        <div>
          {lang.class}: <b>{contract.class}</b>
        </div>
      )}
      {contract.warrantyOption && (
        <div>
          {lang.warrantyOptions}: <b>{contract.warrantyOption}</b>
        </div>
      )}
      {contract.subfees && (
        <>
          <h3>{lang.surcharges}</h3>
          <DataMap
            data={contract.subfees}
            render={({ data: subfee }) => (
              <div key={subfee?.subfeeId}>
                {subfees?.find((s) => s.id.toString() === subfee?.subfeeId?.toString())?.name || lang.unknown}:{' '}
                <b>
                  {subfee?.quantity} x{' '}
                  <FormatValue
                    value={`${subfee?.amount?.toFixed(2)}${
                      !subfee?.currency || subfee?.currency === 'CAD' ? '$' : subfee?.currency
                    }`}
                  />
                </b>
              </div>
            )}
          />
          <hr />
          <div>
            {lang.total}:{' '}
            <b>
              <FormatValue value={`${(contract.surchargesPriceCAD || 0).toFixed(2)}$`} />
            </b>
          </div>
        </>
      )}
      {!isDealerOrDistributor && !!values(contract.pricingDistributions).length && (
        <ObjectInfo
          object={mapValues(
            mapKeys(contract.pricingDistributions, (it, key) => capitalize(key.split('.').join(' - '))),
            (it) => `${it.amount.toFixed(2)}${!it.currency || it.currency === 'CAD' ? '$' : it.currency}`
          )}
          objectName={lang.pricingDistributions}
        />
      )}
      {!isDealer && !!values(contract.distributorDistributions).length && (
        <ObjectInfo
          object={mapValues(
            mapKeys(contract.distributorDistributions, (it, key) => capitalize(key.split('.').join(' - '))),
            (it) => `${it.amount.toFixed(2)}${!it.currency || it.currency === 'CAD' ? '$' : it.currency}`
          )}
          objectName={lang.distributorDistributions}
        />
      )}
      <ObjectInfo
        object={pick(
          { ...contract, totalPrice: new Contract(contract).calculateTotalPrice },
          'id',
          'legacyId',
          'appNumber',
          'effectiveDate',
          'expiryDate',
          'createdAtUtc',
          'modifiedAtUtc',
          'isPackage',
          'postSale',
          'totalPrice',
          'veraForteStatus'
        )}
        objectName={lang.contract}
      />
      {contract.contractConsent && (
        <ObjectInfo
          object={pick(
            {
              ...contract.contractConsent,
              status: isNull(contract.contractConsent.isAccepted)
                ? ContractConsentStatus.WAITING
                : contract.contractConsent.isAccepted
                ? ContractConsentStatus.GIVEN
                : ContractConsentStatus.REJECTED,
              dealerCostPrice: formatNumberWithSeparator(contract.contractConsent.dealerCost),
              term: `${contract.contractConsent.term} months`,
              totalRetailPrice: `${contract.contractConsent.salePriceCAD} + tax`,
            },
            'id',
            'type',
            'createdAtUtc',
            'salePriceCAD',
            'dealerCostPrice',
            'term',
            'insurancePremiumCAD',
            'administrationFeeCAD',
            'limitOfLiabilityAggregateCAD',
            'totalRetailPrice',
            'programName',
            'customerName',
            'customerEmail',
            'coverage',
            'dealership',
            'answeredAt',
            'isAccepted',
            'status'
          )}
          objectName={lang.consent}
        />
      )}
      <ObjectInfo
        object={pick(contract.client, 'firstName', 'lastName', 'email', 'phone', 'taxExempt')}
        objectName={lang.client}
      />
      <ObjectInfo
        object={pick(
          {
            ...contract.vehicle,
            purchaseState:
              contract.vehicle?.purchaseState === PurchaseState.USED
                ? PurchaseState.PRE_OWNED
                : contract.vehicle?.purchaseState,
          },
          'vin',
          'year',
          'makeCode',
          'model',
          'bodyType',
          'posOdometerReading',
          'stockNumber',
          'purchasePrice',
          'purchaseState',
          'purchaseType',
          'inServiceDate',
          'mfgWholeVehicleWarrantyMonths',
          'mfgWholeVehicleWarrantyKm',
          'mfgPowertrainWarrantyMonths',
          'mfgPowertrainWarrantyKm',
          'isNew'
        )}
        objectName={lang.vehicle}
      />
      {contract.vehicle?.lender && (
        <ObjectInfo
          object={pick(
            contract.vehicle?.lender,
            'name',
            'email',
            'phoneNumber',
            'streetAddress',
            'city',
            'provinceCode',
            'countryCode',
            'postalCode'
          )}
          objectName={lang.lender}
        />
      )}
      <ObjectInfo
        object={pick(
          {
            ...contract.dealer,
            primaryPhoneNumber: isValidPhoneNumber(contract.dealer.primaryPhoneNumber)
              ? contract.dealer.primaryPhoneNumber
              : contract.dealer.primaryPhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
          },
          'name',
          'primaryPhoneNumber',
          'primaryEmailAddress',
          'streetAddress',
          'city',
          'provinceCode',
          'countryCode',
          'postalCode'
        )}
        objectName={lang.dealer}
      />
    </>
  );
}
