import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { defaultLimit, defaultOffset, HttpStatus } from 'config/Api.Config';
import Contract from 'models/sales/contract/Model.Contract';
import { addLog } from 'assets/components/feedback/Feedback';
import { first, isEmpty } from 'lodash';
import StatementEmailTemplate from 'models/core/statementEmailTemplate/Model.StatementEmailTemplate';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import StatementStatusCode from 'models/enums/StatementStatusCode';
import statementCreateForDealerApi from 'models/sales/statement/createForDealer/Api.Statement.CreateForDealer';
import statementDeleteApi from 'models/sales/statement/delete/Api.Statement.Delete';
import Statement from 'models/sales/statement/Model.Statement';
import statementRequestPaymentUrlApi from 'models/sales/statement/requestPaymentUrl/Api.Statment.RequestPaymentUrl';
import statementSendEmailApi from 'models/sales/statement/sendEmail/Api.Statement.SendEmail';
import statementSetStatusApi from 'models/sales/statement/setStatus/Api.Statement.SetStatus';
import { StatementEmailTriggers } from 'module/management/views/statementEmailTemplate/StatementEmailTemplate.Hooks';
import { useMemo, useState } from 'react';
import { statementIndexRoute } from './Statement.Index';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';
import { getLocales } from 'assets/locales/Locale';
import statementListApi from 'models/sales/statement/list/Api.Statement.List';

export enum StatementAction {
  CREATE = 'create',
  UPLOAD_DOCUMENT = 'uploadDocument',
  PRINT = 'print',
  CHANGE_STATUS = 'changeStatus',
  SEND_EMAIL = 'sendEmail',
  DELETE = 'delete',
}
export type VerifyStatementAction = Utils.VerifyExtends<
  Module.Claims.Statement.Actions,
  Utils.ValueOf<typeof StatementAction>
>;
export default function useStatement() {
  const { lang } = getLocales();
  const { user, permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Statement.Params, Module.Claims.Statement.Query>({
    route: statementIndexRoute,
  });
  const [selectedStatements, setSelectedStatements] = useState<Model.IStatement[]>([]);

  const canView = permissions.CREATE_STATEMENT || permissions.EDIT_STATEMENT || permissions.DELETE_STATEMENT;

  //#region APIS
  const listApi = useApi({
    action: Statement.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: {
      ...pageRouter.query.list,
      distributorId: user?.distributorId ? [user.distributorId] : undefined,
      dealerId: user?.dealers?.map((it) => it.id),
    },
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const deleteApi = useApi({
    action: statementDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const createApi = useApi({
    action: statementCreateForDealerApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const setStatusApi = useApi({
    action: statementSetStatusApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const dealersApi = useApi({
    action: Dealer.list,
    body: { distributorId: user?.distributorId ? [user.distributorId] : undefined },
  });

  const dealerGroupsApi = useApi({
    action: DealerGroup.list,
    body: { distributorId: user?.distributorId ? [user.distributorId] : undefined },
  });

  const sendEmailApi = useApi({
    action: statementSendEmailApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const statementEmailTemplatesApi = useApi({
    action: StatementEmailTemplate.list,
    body: { statementStatusTrigger: StatementEmailTriggers.SendEmail },
  });
  const statementEmailTemplate = first(statementEmailTemplatesApi.payload?.data);
  //#endregion

  async function payStatement(statement: Statement) {
    if (!statement.dealer) return;
    if (statement.isPayed || statement.status === StatementStatusCode.RECONCILED) return;

    const dealer = statement.dealer;
    const entityIds = [statement.id];
    const paymentUrl = await statementRequestPaymentUrlApi({
      entityIds,
      amount: statement.contracts.reduce((p, c) => {
        const taxExemptPrice = new Contract(c).taxExemptPrices;
        return p + (taxExemptPrice.paymentAmountTotalCAD ?? 0);
      }, 0),
      redirectUrl: `${document.location.origin}${createPathFromRoute(statementIndexRoute)}`,
      client: {
        firstName: '',
        lastName: '',
        address: dealer.streetAddress,
        city: dealer.city,
        province: dealer.provinceCode,
        zip: dealer.postalCode,
        email: dealer.primaryEmailAddress,
        phone: dealer.primaryPhoneNumber,
      },
    });
    if (paymentUrl.payload) {
      window.open(paymentUrl.payload, '_blank', 'noopener, noreferrer');
    }
  }

  async function sendEmailsForSelectedStatements() {
    let totalEmailsToSend = 0;
    let totalEmailsSend = 0;
    let totalEmailsFailed = 0;

    if (!isEmpty(selectedStatements)) {
      for (const statement of selectedStatements) {
        totalEmailsToSend += 1;

        const res = await statementSendEmailApi({
          statementId: statement.id,
          emailTemplate: {
            ...statementEmailTemplate,
            additionalRecipientEmails: [
              ...(statementEmailTemplate?.additionalRecipientEmails || []),
              ...(statement.dealer?.accountingEmails || []),
            ],
          },
        });

        if (res.status === HttpStatus.ok) totalEmailsSend += 1;
        else totalEmailsFailed += 1;
      }
    } else addLog({ warning: lang.noStatementsSelectedForSendingAnEmail });

    if (totalEmailsSend > 0) addLog({ success: `Successfully sent: ${totalEmailsSend}/${totalEmailsToSend} emails` });
    else if (totalEmailsFailed > 0)
      addLog({ error: `Failed to send: ${totalEmailsFailed}/${totalEmailsToSend} emails` });
  }

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const getAllSelectedStatements = async () => {
    const newQuery = { ...pageRouter?.query?.list };
    delete newQuery.limit;
    delete newQuery.offset;
    const {
      payload: { data: filteredStatements },
    } = await statementListApi({ ...newQuery });

    return selectedStatements?.length === filteredStatements?.length ? [] : filteredStatements?.map((it) => it);
  };

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    createApi,
    selected,
    permissions,
    setStatusApi,
    dealersApi,
    dealerGroupsApi,
    sendEmailApi,
    statementEmailTemplate,
    selectedStatements,
    payStatement,
    setSelectedStatements,
    sendEmailsForSelectedStatements,
    getAllSelectedStatements,
    views: {
      [StatementAction.DELETE]:
        pageRouter.params.action === StatementAction.DELETE &&
        selected &&
        permissions.DELETE_STATEMENT &&
        (selected.status === StatementStatusCode.OPEN || selected.status === StatementStatusCode.CANCELED),
      [StatementAction.CREATE]:
        pageRouter.params.action === StatementAction.CREATE &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.UPLOAD_DOCUMENT]:
        pageRouter.params.action === StatementAction.UPLOAD_DOCUMENT &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.PRINT]:
        pageRouter.params.action === StatementAction.PRINT &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
      [StatementAction.CHANGE_STATUS]:
        pageRouter.params.action === StatementAction.CHANGE_STATUS &&
        permissions.UPDATE_STATEMENT_STATUS &&
        !!selected &&
        selected.status !== StatementStatusCode.CANCELED,
      [StatementAction.SEND_EMAIL]:
        pageRouter.params.action === StatementAction.SEND_EMAIL &&
        ((permissions.CREATE_STATEMENT && !selected) || (permissions.EDIT_STATEMENT && selected)),
    },
  };
}

export function useStatementProvider() {
  return useDataProvider<ReturnType<typeof useStatement>>();
}
