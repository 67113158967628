import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import ClaimTypeDataPointInputType from 'models/enums/ClaimTypeDataPointInputType';
import { useState } from 'react';
import ClaimTypeDataPointEditForm from './ClaimType.Edit.DataPointsForm';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';

export default function ClaimTypeEditDataPoints() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const [currentDataPoint, setCurrentDataPoint] = useState<Partial<Model.IClaimTypeDataPoint>>(null);

  const updateDataPoint = (data: Utils.FormData<Model.IClaimTypeDataPoint>, index: number) => {
    const updatedDataPoints = form.data?.claimTypeDataPoints?.map((item, idx) =>
      idx === index ? { ...item, ...data } : item
    );
    form.update({ claimTypeDataPoints: updatedDataPoints });
  };

  return (
    <Collection class={ThemeCollectionTable}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          class={ThemeButtonCircle}
          render={lang.addNew}
          active
          data={{
            id: -1 * (form.data.claimTypeDataPoints?.length || 0),
            name: undefined,
            type: undefined,
          }}
          onClick={setCurrentDataPoint}
        />
        {currentDataPoint && (
          <ClaimTypeDataPointEditForm
            data={currentDataPoint}
            onCancel={() => setCurrentDataPoint(null)}
            onSave={(newDataPoint) => {
              const filteredDataPoints = form.data?.claimTypeDataPoints?.filter((it) => it?.id !== newDataPoint?.id);
              form.update({ claimTypeDataPoints: [...filteredDataPoints, newDataPoint] });
              setCurrentDataPoint(null);
            }}
          />
        )}
      </Box>
      <DataMap
        data={form.data?.claimTypeDataPoints}
        render={({ data: dataPoint, index }) => (
          <Box
            key={`${dataPoint?.id}_${index}`}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            render={
              <>
                <TextInput
                  name="name"
                  label={lang.name}
                  value={dataPoint?.name}
                  onChange={(data) => updateDataPoint({ ...dataPoint, ...data }, index)}
                />
                &nbsp;
                <SelectInput
                  name="type"
                  label={lang.type}
                  data={Object.values(ClaimTypeDataPointInputType).map((it) => ({ id: it, title: it }))}
                  value={dataPoint.type}
                  onChange={(data) => updateDataPoint({ ...dataPoint, ...data }, index)}
                />
                &nbsp;
                <CheckWrapper>
                  <CheckInput
                    name="isRequired"
                    label={lang.isRequired}
                    class={inputStyles.check.switch}
                    value={dataPoint.isRequired}
                    onChange={(data) => updateDataPoint({ ...dataPoint, ...data }, index)}
                    htmlElementProps={{ title: dataPoint.isRequired ? lang.disable : lang.enable }}
                  />
                </CheckWrapper>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
