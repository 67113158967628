import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { combineStrings } from 'assets/utils/data/String';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import { difference, set } from 'lodash';
import dealerDeleteFileApi from 'models/dealerManagement/dealer/deleteFile/Api.Dealer.DeleteFile';
import dealerDeleteProductDocumentApi from 'models/dealerManagement/dealer/deleteProductDocument/Api.Dealer.DeleteProductDocument';
import dealerDeleteUiFileApi from 'models/dealerManagement/dealer/deleteUiFile/Api.Dealer.DeleteUiFile';
import dealerSaveApi from 'models/dealerManagement/dealer/save/Api.Dealer.Save';
import dealerUploadFileApi from 'models/dealerManagement/dealer/uploadFile/Api.Dealer.UploadFile';
import dealerUploadProductDocumentApi from 'models/dealerManagement/dealer/uploadProductDocument/Api.Dealer.UploadProductDocument';
import dealerUploadUiFileApi from 'models/dealerManagement/dealer/uploadUiFile/Api.Dealer.UploadUiFile';
import { useState } from 'react';
import { useDealerProvider } from '../Dealer.Hooks';
import DealerGeneralForm from './edit/Dealer.General';
import DealerProductDocumentForm from './edit/Dealer.ProductDocumentForm';
import DealerUiSettingsForm from './edit/Dealer.UiSettingsForm';

export type DealerForm = Omit<Api.Core.Dealer.Save.IRequest, 'productDealerDocuments'> & {
  files: Hooks.Input.File.Info[];
  logo: Hooks.Input.File.Info;
  productDealerDocuments: Array<
    Utils.Unpacked<Api.Core.Dealer.Save.IRequest['productDealerDocuments']> & {
      formFile?: Hooks.Input.File.Info;
      isDeleted?: boolean;
    }
  >;
};
export default function DealerEdit() {
  const { lang } = getLocales();
  const { user, isDistributor, isDealerOrDistributor, isAdministrator } = useAuthStore();
  const { selected, pageRouter, listApi } = useDealerProvider();
  const [additionalProductIds, setAdditionalProductIds] = useState<number[]>([]);
  return (
    <>
      <ModalForm<DealerForm>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.dealer}
        default={{
          ...selected?.toJSON(),
          representativeId: selected?.representativeId ?? user?.distributorId,
          logo: selected?.uiSettingsFile
            ? {
                id: selected.uiSettingsFile.id,
                dataUrl: selected.uiSettingsFile.url,
                name: selected.uiSettingsFile.name,
                type: selected.uiSettingsFile.contentType as Utils.File.MimeType,
              }
            : null,
          files: selected?.mediaFiles?.map((it) => ({
            id: it.id,
            dataUrl: it.url,
            name: it.name,
            type: it.contentType as Utils.File.MimeType,
          })),
        }}
        onCancel={pageRouter.updateParams}
        onSubmit={async ({ files, logo, productDealerDocuments, ...data }) => {
          const response = await dealerSaveApi(data);

          if (response.status === HttpStatus.ok) {
            let totalFiles = 0;
            let uploadedFiles = 0;
            let deletedFiles = 0;
            let totalDeletedFiles = 0;
            const deletedFileIds = difference(
              selected?.mediaFiles?.map((it) => it.id),
              files?.map((it) => it.id)
            );
            for (const fileId of deletedFileIds || []) {
              const fileUploadResponse = await dealerDeleteFileApi(response.payload || data.id, {
                fileId,
              });
              if (fileUploadResponse.status === HttpStatus.ok) {
                deletedFiles += 1;
              }
              totalDeletedFiles += 1;
            }
            for (const formFile of files?.filter((it) => !it.id && it.original) || []) {
              const fileUploadResponse = await dealerUploadFileApi(response.payload || data.id, {
                formFile: formFile.original,
              });
              if (fileUploadResponse.status === HttpStatus.ok) {
                uploadedFiles += 1;
              }
              totalFiles += 1;
            }
            for (const productDealerDoc of productDealerDocuments || []) {
              if (productDealerDoc.isDeleted && productDealerDoc.id) {
                const docDeleteReponse = await dealerDeleteProductDocumentApi({
                  id: response.payload || data.id,
                  productDealerDocumentId: productDealerDoc.id,
                });
                if (docDeleteReponse.status === HttpStatus.ok) {
                  deletedFiles += 1;
                }
                totalDeletedFiles += 1;
              } else if (productDealerDoc.formFile) {
                const docUploadResponse = await dealerUploadProductDocumentApi(
                  {
                    id: response.payload || data.id,
                    productId: productDealerDoc.productDocument.productId,
                    productDocumentId: productDealerDoc.productDocumentId,
                  },
                  { formFile: productDealerDoc.formFile.original }
                );
                if (docUploadResponse.status === HttpStatus.ok) {
                  uploadedFiles += 1;
                }
                totalFiles += 1;
              }
            }
            if (logo?.id !== selected?.uiSettingsFile?.id) {
              const logoUploadResponse = await dealerDeleteUiFileApi(response.payload || data.id);
              if (logoUploadResponse.status === HttpStatus.ok) {
                deletedFiles += 1;
              }
              totalDeletedFiles += 1;
            }
            if (logo && !logo?.id) {
              const logoUploadResponse = await dealerUploadUiFileApi(response.payload || data.id, {
                formFile: logo.original,
              });
              if (logoUploadResponse.status === HttpStatus.ok) {
                uploadedFiles += 1;
              }
              totalFiles += 1;
            }
            listApi.execute((body) => body);
            if (uploadedFiles !== totalFiles || deletedFiles !== totalDeletedFiles) {
              addLog({
                warning: combineStrings(
                  ' ',
                  `Dealer was successfully saved but some files did not get deleted or uploaded!`,
                  totalFiles > 0 ? `${uploadedFiles}/${totalFiles} files uploaded` : null,
                  totalDeletedFiles > 0 ? `${deletedFiles}/${totalDeletedFiles} files got deleted!` : null
                ),
              });
            } else {
              addLog({ success: lang.saveSuccess });
            }
          } else {
            addLog({ error: response.message });
          }
        }}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.streetAddress) errors.streetAddress = lang.mustNotBeEmpty;
          if (!data.provinceCode) errors.provinceCode = lang.mustNotBeEmpty;
          if (!data.city) errors.city = lang.mustNotBeEmpty;
          if (!data.countryCode) errors.countryCode = lang.mustNotBeEmpty;
          if (!data.postalCode) errors.postalCode = lang.mustNotBeEmpty;
          if (!data.representativeId) errors.representativeId = lang.mustNotBeEmpty;
          if (data.primaryPhoneNumber && !isValidPhoneNumber(data.primaryPhoneNumber))
            set(errors, 'primaryPhoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
          if (data.primaryEmailAddress && !isEmail(data.primaryEmailAddress))
            set(errors, 'primaryEmailAddress', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
          if (data.claimPaymentEmail && !isEmail(data.claimPaymentEmail))
            set(errors, 'claimPaymentEmail', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
          if (data.accountingEmails && data.accountingEmails?.some((it) => it && !isEmail(it)))
            set(errors, 'accountingEmails', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
        }}
        tabs={[
          ...(isDistributor || isDealerOrDistributor || isAdministrator
            ? [{ id: 'info', icon: 'fas-info-circle', text: lang.info }]
            : []),
          ...(isDealerOrDistributor || isAdministrator
            ? [{ id: 'productDocuments', icon: 'fas-file', text: lang.productDocuments }]
            : []),
          ...(isDistributor || isDealerOrDistributor || isAdministrator
            ? [{ id: 'uiSettings', icon: 'fas-paint-roller', text: lang.uiSettings }]
            : []),
        ]}
        render={({ activeTab }) =>
          activeTab?.id === 'productDocuments' ? (
            <DealerProductDocumentForm
              additionalProductIds={additionalProductIds}
              setAdditionalProductIds={setAdditionalProductIds}
            />
          ) : activeTab?.id === 'uiSettings' ? (
            <DealerUiSettingsForm />
          ) : activeTab?.id === 'info' || !activeTab?.id ? (
            <DealerGeneralForm
              additionalProductIds={additionalProductIds}
              setAdditionalProductIds={setAdditionalProductIds}
            />
          ) : null
        }
      />
    </>
  );
}
