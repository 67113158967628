import useApi from 'assets/hooks/api/useApi';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { isEmpty } from 'assets/utils/parsersAndValidation/Validators';
import { first, omit } from 'lodash';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Distributor from 'models/sales/distributor/Model.Distributor';
import DistributorProduct from 'models/sales/distributorProduct/Model.ProductDistributor';
import distributorProductSaveApi from 'models/sales/distributorProduct/save/Api.ProductDistributor.Save';
import {
  distributorProductPricingDistributionDeleteApi,
  distributorProductPricingDistributionSaveApi,
} from 'models/sales/distributorProductPricingDistribution/Model.DistributorProductPricingDistribution';
import { useEffect, useState } from 'react';
import { ProgressKey } from '../../../assets/components/progressWindow/ProgressWindow';

type PricingDistribution = {
  id?: number;
  distributorProductId?: number;
  pricePointId: number;
  pricePointType: Utils.PricePointType;
  distributions: Utils.PricingDistribution;
};
export type DistributorPricePoint = {
  isUpdated?: boolean;
  templateId?: number;
  template?: Model.IPricingDistributionTemplate;
  distributions?: Array<PricingDistribution & { isUpdated?: boolean }>;
};
type Props = {
  productId: number;
};

export default function useDistributorPricingDistributions({ productId }: Props) {
  const { user, isDealer, isDistributor, isDealerOrDistributor } = useAuthStore();
  const [distributorId, setDistributorId] = useState<number | null>(user?.distributorId);

  const distributorApi = useApi({
    action: Distributor.list,
    body: { id: distributorId ? [distributorId] : undefined },
    wait: isDealer,
  });
  const distributorProductsApi = useApi(
    {
      action: DistributorProduct.list,
      body: { distributorId, productId },
      wait: isDealer || !distributorId || !productId,
    },
    [distributorId, productId, isDealer]
  );

  const templatesApi = useApi({
    action: PricingDistributionTemplate.list,
    wait: true,
  });

  useEffect(() => {
    if (isDealer || !distributorId) {
      templatesApi.reset(true);
    } else {
      templatesApi.execute({ distributorId });
    }
    setDistributorData({
      template: null,
      templateId: null,
      distributions: null,
      isUpdated: false,
    });
  }, [distributorId, isDealer]);
  //#region DISTRIBUTOR
  const distributor = distributorApi?.payload?.data?.find((d) => d.id == distributorId);
  const productRelation = first(distributorProductsApi?.payload?.data);
  const [distributorData, setDistributorData] = useState<DistributorPricePoint>({
    templateId: productRelation?.pricingDistributionTemplateId,
    template: productRelation?.pricingDistributionTemplate,
    distributions: productRelation?.pricingDistributions,
  });
  useEffect(() => {
    if (productRelation) {
      const template =
        productRelation?.pricingDistributionTemplate ??
        templatesApi.payload?.data?.find((it) => it.id === productRelation.pricingDistributionTemplateId);
      setDistributorData({
        isUpdated: false,
        templateId: template?.id,
        template,
        distributions: productRelation?.pricingDistributions,
      });
    }
  }, [productRelation, templatesApi.payload?.data]);
  function getDistributorPrice(pricePointId: number, pricePointType: Utils.PricePointType) {
    return distributorData.distributions?.find(
      (it) => it.pricePointId === pricePointId && it.pricePointType === pricePointType
    );
  }
  function updateDistributorPrice(pricingDistribution: PricingDistribution) {
    const currentDistribution = getDistributorPrice(
      pricingDistribution.pricePointId,
      pricingDistribution.pricePointType
    );
    setDistributorData((old) => ({
      ...old,
      distributions: [
        ...(old.distributions?.filter((it) => it.pricePointId !== pricingDistribution.pricePointId) ?? []),
        ...(isEmpty(pricingDistribution?.distributions)
          ? []
          : [{ ...currentDistribution, ...pricingDistribution, isUpdated: true }]),
      ].filter((it) => it.pricePointId && it.pricePointType),
    }));
  }
  //#endregion

  async function saveAll(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    let savedId = productRelation?.id;
    if (distributorData.isUpdated) {
      const response = await distributorProductSaveApi({
        productId,
        distributorId,
        ...omit(productRelation, 'pricingDistributions'),
        pricingDistributionTemplateId: distributorData.templateId,
      });
      if (response.payload) savedId = response.payload;
    }
    const updatedDistributions = distributorData.distributions?.filter((d) => d.isUpdated) ?? [];
    const updatedLimitCount = await iterateWithProgress(
      updatedDistributions,
      async (distribution) => {
        if (!distribution.distributions && distribution.id) {
          await distributorProductPricingDistributionDeleteApi(distribution.id);
        } else {
          await distributorProductPricingDistributionSaveApi({
            ...distribution,
            distributorProductId: savedId,
          });
        }
      },
      (p) => onSaveProgress('distributorPriceDistribution', p)
    );

    if (updatedLimitCount || distributorData.isUpdated) await distributorProductsApi.execute((b) => b);
  }

  return {
    saveAll,
    getDistributorPrice,
    updateDistributorPrice,
    distributorId,
    setDistributorId,
    setDistributorData,
    distributors: distributorApi?.payload?.data,
    distributorData,
    distributor,
    productRelation,
    templates: templatesApi?.payload?.data,
    canEdit: isDistributor || !isDealerOrDistributor,
    isDealerOrDistributor,
  };
}
