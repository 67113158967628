import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { date, defaultServerDateFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { convertProductPropertyByInputType } from 'assets/utils/other/Utils.Other.ProductInputTypeMappings';
import { first, flatten, get, last, random, set } from 'lodash';
import draftDeleteApi from 'models/core/draft/delete/Api.Draft.Delete';
import draftListApi from 'models/core/draft/list/Api.Draft.List';
import draftSaveApi from 'models/core/draft/save/Api.Draft.Save';
import InputType from 'models/enums/InputType';
import ProductDocumentPropertyPath from 'models/enums/ProductDocumentPropertyPath';
import Vehicle from 'models/productManagement/vehicle/Model.Vehicle';
import contractSaveApi from 'models/sales/contract/save/Api.Contract.Save';
import { useEffect, useMemo, useState, useRef } from 'react';
import { salesNewContractIndexRoute } from './Sales.NewContract.Index';
import Contract from 'models/sales/contract/Model.Contract';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Product from 'models/productManagement/product/Model.Product';
import contractProductDocumentSaveApi from 'models/sales/contractProductDocuments/save/Api.ContractProductDocument.Save';
import contractGenerateDocumentsApi from 'models/sales/contract/generateDocuments/Api.Contract.GenerateDocuments';
import { HttpStatus } from 'config/Api.Config';
import contractProductDocumentDeleteApi from 'models/sales/contractProductDocuments/delete/Api.ContractProductDocument.Delete';
import mediaFileSave from 'models/core/mediaFile/save/Api.MediaFile.Save';
import revisionProductDocumentSaveApi from 'models/sales/contractProductDocuments/saveRevision/Api.RevisionProductDocument.Save';
import revisionGenerateDocumentsApi from 'models/sales/contract/generateRevisionDocuments/Api.Contract.GenerateRevisionDocuments';
import distributorFindApi from 'models/sales/distributor/find/Api.Distributor.Find';
import calculatePricesForContract, { ProductPricingInfo } from 'app/scripts/contract/calculatePricesForContract';
import { unstable_batchedUpdates } from 'react-dom';
import Lender from 'models/sales/lender/Model.Lender';
import PurchaseType from 'models/enums/PurchaseType';
import PurchaseState from 'models/enums/PurchaseState';
import contractSendDocumentForSigningApi from 'models/sales/contract/sendDocumentForSigning/Api.Contract.SendDocumentForSigning';
import { isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import contractSendConsentApi from 'models/sales/contract/sendConsent/Api.Contract.SendConsent';

export type ContractExtraInfo = {
  effectiveDate?: string;
  expiryDate?: string;
  termInMonths?: number;
  deductible?: number;
  maxVehiclePriceCAD?: number;
  minVehiclePriceCAD?: number;
  class?: string;
  warrantyOption?: string;
  postSale?: boolean;
  isPackage?: boolean;
  tier?: string;
  customInfo?: string;
  customInfoGroup?: string;
  pricePointId?: number;
  maxKm?: number;
  salePriceCAD?: number;
  priceCAD?: number;
  minPriceCAD?: number;
  maxPriceCAD?: number;
  originalPriceCAD?: number;
  distributorPriceCAD?: number;
  surchargesPriceCAD?: number;
  discountPriceCAD?: number;
  pricingDistributions?: Utils.PricingDistribution;
  distributorDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  totalPrice?: number;
  taxExempt?: boolean;
  additionalFields?: Array<{ productDocumentId: number; key: string; value: any }>;
  documentsFromTemplates?: Array<
    Partial<Model.IProductDocumentTemplate> & {
      notes?: string;
      formFile?: File;
      isDeleted?: boolean;
      uploadedId?: number; //Id of a previous IContractProductDocument
      mediaFileId?: number; //Id for IContractProductDocument.mediaFile from draft
    }
  >;
};
export type ContractProduct = Model.IProduct & ContractExtraInfo;
export type ProductForm = Utils.FormData<Model.IContract> & {
  products?: Array<ContractProduct>;
  distributor?: Model.IDistributor;
  draftId?: number;
};

export type SaveableContractData = Utils.FormData<Model.IContract> &
  ContractExtraInfo & {
    distributor?: Model.IDistributor;
    draftId?: number;
    product: Model.IProduct;
  };

export enum SalesNewContractAction {
  VEHICLE = 'vehicle',
  PRODUCTS = 'products',
  CLIENT = 'client',
  CONTRACT = 'contract',
  REVIEW = 'review',
  FINISH = 'finish',
}

export type ContractDraft = {
  form: ProductForm;
  dateUpdated: string;
};

const DRAFT_TYPE = 'Contract';
export enum ContractSaveStatus {
  waiting = 'contractStatusWaiting',
  saving = 'contractStatusSaving',
  generatingDocs = 'contractStatusGeneratingDocs',
  uploadingFiles = 'contractStatusUploadingFiles',
  done = 'contractStatusDone',
  failed = 'contractStatusFailed',
}

export default function useSalesNewContract() {
  const { lang } = getLocales();
  const { permissions, userId } = useAuthStore();
  const pageRouter = usePageRouter<Module.Sales.NewContract.Params, Module.Sales.NewContract.Query>({
    route: salesNewContractIndexRoute,
  });
  const [contractStatuses, setContractStatuses] = useState<
    Array<{
      contract: SaveableContractData;
      status: ContractSaveStatus;
      documentCount: number;
      uploadedDocuments: number;
      areWeGeneratingDocuments: boolean;
      areDocumentsGenerated: boolean;
      areDocumentsAutomaticallySentForSigning: boolean;
      isPaymentRequired: boolean;
      isSignRequired: boolean;
      isCustomerConsentRequired: boolean;
    }>
  >([]);
  const id = pageRouter.params.id && !isNaN(parseInt(pageRouter.params.id)) ? parseInt(pageRouter.params.id) : null;
  const contractFindApi = useApi({
    action: Contract.find,
    wait: !id,
    body: {
      id: id ?? 0,
    },
  });
  //#region Data editing
  useEffect(() => {
    (async () => {
      if (!contractFindApi.payload) return;

      const vehicleModels = await VehicleModel.list({
        makeCode: contractFindApi.payload.vehicle.makeCode,
        modelCode: contractFindApi.payload.vehicle.model,
      });
      const vehicleModel = first(vehicleModels.payload?.data || [])?.toJSON();
      const dealerFind = await Dealer.find({ id: contractFindApi.payload.dealerId });
      const productFind = await Product.find({ id: contractFindApi.payload.productId });
      const lenderFind = await Lender.find({ id: contractFindApi.payload.vehicle?.lenderId });
      productChangeDependencies.current = null;
      form.update({
        ...contractFindApi.payload,
        dealer: dealerFind.payload,
        vehicle: {
          ...contractFindApi.payload?.vehicle,
          lender: lenderFind.payload,
          purchaseState:
            contractFindApi.payload?.vehicle.purchaseState === PurchaseState.USED
              ? PurchaseState.PRE_OWNED
              : contractFindApi.payload?.vehicle.purchaseState,
          vehicleModel,
        },
        product: productFind.payload,
        products: !productFind.payload
          ? []
          : [
              {
                ...productFind.payload,
                effectiveDate: contractFindApi.payload.effectiveDate,
                expiryDate: contractFindApi.payload.expiryDate,
                termInMonths: contractFindApi.payload.termInMonths,
                customInfo: contractFindApi.payload.customInfo,
                customInfoGroup: contractFindApi.payload.customInfoGroup,
                minVehiclePriceCAD: contractFindApi.payload.minVehiclePriceCAD,
                maxVehiclePriceCAD: contractFindApi.payload.maxVehiclePriceCAD,
                class: contractFindApi.payload.class,
                warrantyOption: contractFindApi.payload.warrantyOption,
                postSale: contractFindApi.payload.postSale,
                isPackage: contractFindApi.payload.isPackage,
                tier: contractFindApi.payload.tier,
                maxKm: contractFindApi.payload.maxKm,
                deductible: contractFindApi.payload.deductible,
                salePriceCAD: contractFindApi.payload.salePriceCAD,
                priceCAD: contractFindApi.payload.priceCAD,
                distributorPriceCAD: contractFindApi.payload.distributorPriceCAD,
                surchargesPriceCAD: contractFindApi.payload.surchargesPriceCAD,
                discountPriceCAD: contractFindApi.payload.discountPriceCAD,
                originalPriceCAD: contractFindApi.payload.originalPriceCAD,
                pricingDistributions: contractFindApi.payload.pricingDistributions,
                distributorDistributions: contractFindApi.payload.distributorDistributions,
                subfees: contractFindApi.payload.subfees,
                totalPrice: contractFindApi.payload.calculateTotalPrice,
                taxExempt: contractFindApi.payload.client.taxExempt,
                documentsFromTemplates: [
                  ...(productFind.payload.productDocumentTemplates?.map((it) => {
                    const alreadyUploaded = contractFindApi.payload.productDocuments?.find((d) => d.name === it.name);
                    return {
                      ...it,
                      uploadedId: alreadyUploaded?.id,
                      notes: alreadyUploaded?.notes,
                    };
                  }) ?? []),
                  ...(contractFindApi.payload.productDocuments
                    ?.filter((d) => !productFind.payload.productDocumentTemplates?.some((t) => t.name === d.name))
                    ?.map((d) => ({
                      name: d.name,
                      notes: d.notes,
                      isDeleted: false,
                      uploadedId: d.id,
                    })) ?? []),
                ],
              },
            ],
      });
    })();
  }, [contractFindApi.payload]);
  const form = useForm<ProductForm>({
    default: { products: [], draftId: random(false), isPackage: false },
    onSubmit: async ({ products, ...contract }) => {
      const contracts =
        products?.map(
          ({
            termInMonths,
            maxKm,
            deductible,
            customInfo,
            customInfoGroup,
            minVehiclePriceCAD,
            maxVehiclePriceCAD,
            class: vehicleClass,
            tier,
            warrantyOption,
            postSale,
            isPackage,
            isCustomerConsentRequired,
            priceCAD,
            distributorPriceCAD,
            surchargesPriceCAD,
            discountPriceCAD,
            originalPriceCAD,
            minPriceCAD,
            maxPriceCAD,
            salePriceCAD,
            pricingDistributions,
            distributorDistributions,
            expiryDate,
            effectiveDate,
            subfees,
            additionalFields,
            documentsFromTemplates,
            ...product
          }) => ({
            ...contract,
            vehicle: {
              ...contract.vehicle,
              purchaseState:
                contract.vehicle?.purchaseState === PurchaseState.PRE_OWNED
                  ? PurchaseState.USED
                  : contract.vehicle?.purchaseState,
            },
            minVehiclePriceCAD,
            maxVehiclePriceCAD,
            class: vehicleClass,
            warrantyOption,
            tier,
            product,
            termInMonths,
            customInfo,
            customInfoGroup,
            maxKm,
            postSale,
            isPackage,
            isCustomerConsentRequired,
            deductible,
            priceCAD,
            distributorPriceCAD,
            surchargesPriceCAD,
            discountPriceCAD,
            originalPriceCAD,
            minPriceCAD,
            maxPriceCAD,
            salePriceCAD,
            pricingDistributions,
            distributorDistributions,
            expiryDate,
            effectiveDate,
            subfees,
            additionalFields,
            documentsFromTemplates,
          })
        ) ?? [];

      setContractStatuses(
        contracts.map((contract) => ({
          contract,
          status: ContractSaveStatus.waiting,
          documentCount: 0,
          areWeGeneratingDocuments: false,
          areDocumentsGenerated: false,
          areDocumentsSigned: false,
          areDocumentsAutomaticallySentForSigning: false,
          isPaymentRequired: false,
          isSignRequired: false,
          isCustomerConsentRequired: contract?.isCustomerConsentRequired,
          uploadedDocuments: 0,
        }))
      );
      let index = 0;
      for (const { additionalFields, documentsFromTemplates, ...contract } of contracts) {
        const transferableDocs = documentsFromTemplates?.filter((it) => it.mediaFileId && !it.isDeleted);
        const uploadableDocs = documentsFromTemplates?.filter(
          (it) => !it.mediaFileId && (it.formFile || (it.isDeleted && it.uploadedId))
        );
        const productDocuments = contract.product?.productDocuments ?? [];
        const hasSignatureFiels = !!productDocuments?.some((it) => !!it.productSignatureFields?.length);
        setContractStatuses((old) => {
          old[index].status = ContractSaveStatus.saving;
          old[index].documentCount = (uploadableDocs?.length ?? 0) + (transferableDocs?.length ?? 0);
          old[index].areWeGeneratingDocuments = !!productDocuments.length;
          old[index].isPaymentRequired =
            !contract.revisionNumber && !contract.isPayed ? contract.dealer?.isPaymentRequired : false;
          old[index].isSignRequired =
            !contract.revisionNumber && !contract.isSigned && !contract.isSentForSigning ? hasSignatureFiels : false;
          return [...old];
        });
        contract.productDocuments = transferableDocs?.map((doc) => ({
          name: doc.name,
          notes: doc.notes,
          mediaFileId: doc.mediaFileId,
        }));
        const contractResponse = await contractSaveApi(contract);
        if (contractResponse.status !== HttpStatus.ok) {
          addLog({ error: contractResponse.message });
        }
        if (transferableDocs?.length) {
          setContractStatuses((old) => {
            old[index].uploadedDocuments = transferableDocs?.length ?? 0;
            return [...old];
          });
        }
        const contractId = contractResponse?.payload;
        if (contractId) {
          const refetchedContractResponse = await Contract.find({ id: contractId });
          const refetchedContract = refetchedContractResponse?.payload;
          if (contract?.isCustomerConsentRequired) {
            setContractStatuses((old) => {
              old[index].contract = refetchedContract;
              old[index].status = ContractSaveStatus.done;
              old[index].isCustomerConsentRequired = true;
              return [...old];
            });
          } else {
            if (refetchedContract) {
              if (uploadableDocs?.length) {
                setContractStatuses((old) => {
                  old[index].status = ContractSaveStatus.uploadingFiles;
                  old[index].contract = refetchedContract;
                  return [...old];
                });
                for (const doc of uploadableDocs) {
                  let wasItSuccessfull = false;
                  if (doc.formFile) {
                    const docUploadResponse = refetchedContract.revisionNumber
                      ? await revisionProductDocumentSaveApi({
                          formFile: doc.formFile,
                          name: doc.name,
                          notes: doc.notes,
                          contractId: refetchedContract.id,
                        })
                      : await contractProductDocumentSaveApi({
                          formFile: doc.formFile,
                          name: doc.name,
                          notes: doc.notes,
                          contractId: refetchedContract.id,
                        });
                    wasItSuccessfull = docUploadResponse.status === HttpStatus.ok;
                  }
                  if (doc.isDeleted && doc.uploadedId) {
                    const docDeleteResponse = await contractProductDocumentDeleteApi({
                      id: refetchedContract.id,
                      documentId: doc.uploadedId,
                    });
                    wasItSuccessfull = docDeleteResponse.status === HttpStatus.ok;
                  }
                  if (wasItSuccessfull) {
                    setContractStatuses((old) => {
                      old[index].uploadedDocuments += 1;
                      return [...old];
                    });
                  }
                }
              }
              const refetchedLender = (await Lender.find({ id: refetchedContract.vehicle?.lenderId }))?.payload;
              const finalFields = flatten(productDocuments.map((d) => d.productAdditionalFields)).map((f) => {
                if (f.propertyPath) {
                  return {
                    productDocumentId: f.productDocumentId,
                    key: f.key,
                    value: convertProductPropertyByInputType({
                      propertyPath: f.propertyPath as ProductDocumentPropertyPath,
                      value: get(
                        {
                          contract: {
                            ...refetchedContract,
                            vehicle: {
                              ...refetchedContract.vehicle,
                              lender: refetchedLender,
                            },
                          },
                        },
                        f.propertyPath
                      ),
                      inputLabel: f.label || f.key,
                      inputType: f.type as InputType,
                      options: {
                        isPackage: refetchedContract.isPackage,
                        isTaxExempt: refetchedContract.client.taxExempt,
                      },
                    }),
                  };
                } else {
                  const additionalField = additionalFields?.find(
                    (it) => it.key == f.key && it.productDocumentId === f.productDocumentId
                  );
                  return {
                    productDocumentId: f.productDocumentId,
                    key: f.key,
                    value: convertProductPropertyByInputType({
                      value: additionalField?.value,
                      inputLabel: f.label || f.key,
                      inputType: f.type as InputType,
                      options: {
                        isPackage: refetchedContract.isPackage,
                        isTaxExempt: refetchedContract.client.taxExempt,
                      },
                    }),
                  };
                }
              });

              if (finalFields?.length) {
                setContractStatuses((old) => {
                  old[index].status = ContractSaveStatus.generatingDocs;
                  return [...old];
                });
                const docGenerationResponse = refetchedContract.revisionNumber
                  ? await revisionGenerateDocumentsApi({
                      contractId: refetchedContract.id,
                      additionalFields: finalFields,
                    })
                  : await contractGenerateDocumentsApi({
                      contractId: refetchedContract.id,
                      additionalFields: finalFields,
                    });
                if (docGenerationResponse?.status === HttpStatus.ok) {
                  setContractStatuses((old) => {
                    old[index].areDocumentsGenerated = true;
                    return [...old];
                  });
                }
              }

              const { payload: finalContract } = await Contract.find({ id: contractId });
              const isPaymentRequired =
                !contract.revisionNumber && !contract.isPayed ? contract.dealer?.isPaymentRequired : false;
              if (!finalContract.isSigned && !finalContract.isSentForSigning && !isPaymentRequired) {
                const docSigningResponse = await contractSendDocumentForSigningApi({
                  contractId: finalContract.id,
                });

                if (docSigningResponse?.status === HttpStatus.ok) {
                  setContractStatuses((old) => {
                    old[index].areDocumentsAutomaticallySentForSigning = true;
                    return [...old];
                  });
                }
              }
              setContractStatuses((old) => {
                old[index].contract = finalContract;
                old[index].status = ContractSaveStatus.done;
                return [...old];
              });
            } else {
              setContractStatuses((old) => {
                old[index].status = ContractSaveStatus.failed;
                return [...old];
              });
            }
          }
        } else {
          setContractStatuses((old) => {
            old[index].status = ContractSaveStatus.failed;
            return [...old];
          });
        }
        index = index + 1;
      }
      await deleteDraft();
    },
    validation: (data, errors) => {
      if (data.client?.phone && !isValidPhoneNumber(data.client?.phone))
        set(errors, 'client.phone', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
      if (
        data.products?.some(
          (p) =>
            p.maxPriceCAD &&
            p.salePriceCAD > p.maxPriceCAD + (p.surchargesPriceCAD | 0) &&
            p.maxPriceCAD + (p.surchargesPriceCAD | 0) > p.minPriceCAD
        )
      ) {
        errors.salePriceCAD = lang.invalidForm;
      }
      if (!data.client?.firstName) set(errors, 'client.firstName', lang.mustNotBeEmpty);
      if (!data.client?.lastName) set(errors, 'client.lastName', lang.mustNotBeEmpty);
      if (!data.client?.email) set(errors, 'client.email', lang.mustNotBeEmpty);
      if (!data.client?.phone) set(errors, 'client.phone', lang.mustNotBeEmpty);
      if (!data.client?.streetAddress) set(errors, 'client.streetAddress', lang.mustNotBeEmpty);
      if (!data.client?.city) set(errors, 'client.city', lang.mustNotBeEmpty);
      if (!data.client?.provinceCode) set(errors, 'client.provinceCode', lang.mustNotBeEmpty);
      if (!data.client?.postalCode) set(errors, 'client.postalCode', lang.mustNotBeEmpty);
      if (!data.client?.countryCode) set(errors, 'client.countryCode', lang.mustNotBeEmpty);
      if (data.client?.taxExempt && !data.client?.taxExemptionBand)
        set(errors, 'client.taxExemptionBand', lang.mustNotBeEmpty);
      if (!data.dealer) set(errors, 'dealer._objectError', lang.mustNotBeEmpty);
      if (!data.vehicle) set(errors, 'vehicle._objectError', lang.mustNotBeEmpty);
      if (!data.vehicle?.vin) set(errors, 'vehicle.vin', lang.mustNotBeEmpty);
      if (!data.vehicle?.makeCode) set(errors, 'vehicle.makeCode', lang.mustNotBeEmpty);
      if (!data.vehicle?.model) set(errors, 'vehicle.model', lang.mustNotBeEmpty);
      if (!data.vehicle?.year) set(errors, 'vehicle.year', lang.mustNotBeEmpty);
      if (
        data.vehicle?.purchaseType &&
        !(
          data.vehicle?.purchaseType === PurchaseType.CASH || data.vehicle?.purchaseType === PurchaseType.LINE_OF_CREDIT
        ) &&
        !data.vehicle?.lenderId
      )
        set(errors, 'vehicle.lenderId', lang.mustNotBeEmpty);
      if (data.vehicle?.purchaseType === PurchaseType.FINANCE && !data.vehicle?.financedAmount)
        set(errors, 'vehicle.financedAmount', lang.invalidForm);
      if (data.product?.isPurchaseDetailsVisible && !data.vehicle?.purchaseState)
        set(errors, 'vehicle.purchaseState', lang.mustNotBeEmpty);
      if (!data.vehicle?.inServiceDate && data.vehicle?.purchaseState === PurchaseState.NEW)
        set(errors, 'vehicle.inServiceDate', lang.mustNotBeEmpty);
      if (
        !data.vehicle?.purchasePrice &&
        (data.product?.isPurchaseDetailsVisible || data.dealer?.products?.some((p) => p.isPurchaseDetailsVisible))
      )
        set(errors, 'vehicle.purchasePrice', lang.mustNotBeEmpty);
      if (!data.products?.some((p) => p.id)) set(errors, 'products._objectError', lang.mustNotBeEmpty);
      if (data.products?.some((p) => !p.effectiveDate)) errors.effectiveDate = lang.mustNotBeEmpty;
      if (data.products?.some((p) => !p.expiryDate)) errors.expiryDate = lang.mustNotBeEmpty;
      if (!data.vehicle?.posOdometerReading) set(errors, 'vehicle.posOdometerReading', lang.mustNotBeEmpty);
      if (
        data.products?.some(
          (p) =>
            !!p.documentsFromTemplates?.length &&
            p.documentsFromTemplates?.some(
              (t) =>
                !!t.id &&
                (t.uploadedId || t.mediaFileId
                  ? t.isDeleted
                    ? !t.formFile && t.required
                    : false
                  : !t.formFile && t.required)
            )
        )
      )
        set(errors, 'products.documentsFromTemplates', lang.notAllRequiredDocumentInfoWasProvided);
    },
  });

  const draftApi = useApi({ action: draftListApi, body: { entity: DRAFT_TYPE } });
  useEffect(() => {
    const draft = draftApi.payload?.data?.find((d) => d.id === pageRouter.query.draftId);
    if (draft) {
      productChangeDependencies.current = null;
      const draftForm = draft.data?.form as ProductForm;
      setSearchedVin(draftForm?.vehicle?.vin);
      form.set(draftForm);
    }
  }, [draftApi.payload, pageRouter.query.draftId]);
  const productChangeDependencies = useRef({ vehicle: form.data.vehicle, dealerId: form.data.dealerId });
  useEffect(() => {
    const prevVehicle = productChangeDependencies.current;
    if (
      !contractFindApi.payload &&
      (prevVehicle?.vehicle?.posOdometerReading !== form.data.vehicle?.posOdometerReading ||
        prevVehicle?.vehicle?.vehicleModel?.id !== form.data.vehicle?.vehicleModel?.id ||
        prevVehicle?.vehicle?.purchaseState !== form.data.vehicle?.purchaseState ||
        prevVehicle?.vehicle?.purchasePrice !== form.data.vehicle?.purchasePrice ||
        prevVehicle?.dealerId !== form.data.dealerId)
    ) {
      if (prevVehicle) form.update({ products: [] });
      productChangeDependencies.current = { vehicle: form.data.vehicle, dealerId: form.data.dealerId };
    }
  }, [
    contractFindApi.payload,
    form.data.vehicle?.posOdometerReading,
    form.data.vehicle?.vehicleModel?.id,
    form.data.vehicle?.purchaseState,
    form.data.vehicle?.purchasePrice,
    form.data.dealerId,
  ]);
  //#endregion

  //#region Vehicle
  const [searchedVin, setSearchedVin] = useState(form.data?.vehicle?.vin || '');

  const vehicleDetails = useApi({ action: Vehicle.details, wait: true });
  function fetchVehicles() {
    if (searchedVin) vehicleDetails.execute({ vin: searchedVin });
    else vehicleDetails.reset();
  }
  useEffect(() => {
    if (
      vehicleDetails?.payload &&
      form?.data?.vehicle?.vin !== vehicleDetails?.payload?.vin &&
      !vehicleDetails?.isExecuting
    ) {
      form.update({
        vehicle: vehicleDetails?.payload?.toJSON() || null,
      });
    }
  }, [vehicleDetails.payload]);
  //#endregion

  //#region Tabs
  const tabs = [
    {
      id: SalesNewContractAction.VEHICLE,
      hasErrors: !!form.errors.vehicle,
      label: lang.vehicleInfo,
    },
    {
      id: SalesNewContractAction.PRODUCTS,
      hasErrors: !!form.errors.dealer || !!form.errors.products?._objectError,
      label: lang.products,
    },
    {
      id: SalesNewContractAction.CONTRACT,
      hasErrors:
        !!form.errors.effectiveDate || !!form.errors.expiryDate || !!form.errors.priceCAD || !!form.errors.salePriceCAD,
      label: lang.contract,
    },
    {
      id: SalesNewContractAction.CLIENT,
      hasErrors: !!form.errors.client,
      label: lang.client,
    },
    {
      id: SalesNewContractAction.REVIEW,
      hasErrors: !!form.errors.products?.documentsFromTemplates,
      label: lang.review,
    },
    {
      id: SalesNewContractAction.FINISH,
      hasErrors: !!form.hasErrors,
      label: lang.finish,
    },
  ];
  const activeTab = tabs.find((it) => it.id === pageRouter.params.action) ?? first(tabs);
  const nextTab = useMemo(() => tabs[tabs.findIndex((it) => it.id === activeTab.id) + 1] || last(tabs), [activeTab.id]);
  const prevTab = useMemo(
    () => tabs[tabs.findIndex((it) => it.id === activeTab.id) - 1] || first(tabs),
    [activeTab.id]
  );

  function next() {
    if (activeTab.id === nextTab.id) addLog({ error: lang.invalidForm });
    if (nextTab.id === SalesNewContractAction.REVIEW) {
      if (form.data.vehicle.purchaseState === PurchaseState.USED) {
        const vehicle = form.data.vehicle;
        vehicle.inServiceDate = null;
        form.merge({ vehicle });
      }
    }
    if (nextTab.id === SalesNewContractAction.FINISH) {
      form.submit();
      if (!form.hasErrors) {
        pageRouter.updateParams({ action: nextTab.id, id: id?.toString() });
      }
    } else {
      pageRouter.updateParams({ action: nextTab.id, id: id?.toString() });
    }
  }
  function prev() {
    if (activeTab.id === nextTab.id) addLog({ error: lang.invalidForm });
    else pageRouter.updateParams({ action: prevTab.id, id: id?.toString() });
  }
  function openTab(tab: SalesNewContractAction) {
    if (id) {
      pageRouter.updateParams({ action: tab, id: id?.toString() });
    }
  }
  function reset() {
    setContractStatuses([]);
    setSearchedVin('');
    vehicleDetails.reset(true);
    form.reload();
    pageRouter.updateParams({ action: SalesNewContractAction.VEHICLE, id: id?.toString() });
  }
  async function saveDraft() {
    const draftForm = form.data;

    for (const draftProduct of draftForm.products ?? []) {
      for (const doc of draftProduct.documentsFromTemplates?.filter((it) => it.formFile) ?? []) {
        if (doc.formFile) {
          const mediaFileSaveResponse = await mediaFileSave({ formFile: doc.formFile });
          doc.mediaFileId = mediaFileSaveResponse.payload ?? undefined;
          doc.formFile = null;
        }
      }
    }

    const response = await draftSaveApi({
      id: pageRouter.query.draftId,
      createdById: userId,
      entityName: DRAFT_TYPE,
      data: {
        form: draftForm,
        dateUpdated: date().tz('UTC').format(defaultServerDateFormat),
      },
    });
    draftApi.execute((body) => body);
    form.update(draftForm);
    pageRouter.updateQuery({ ...pageRouter.query, draftId: response.payload });
  }
  async function deleteDraft() {
    if (!pageRouter.query.draftId) return;
    const response = await draftDeleteApi(pageRouter.query.draftId);
    if (response) {
      draftApi.execute((body) => body);
      pageRouter.updateQuery({});
      form.reload();
    }
  }
  //#endregion

  //#region DISTRIBUTOR
  useEffect(() => {
    (async () => {
      if (form.data.dealer?.representativeId) {
        const distributor = (await distributorFindApi({ id: form.data.dealer?.representativeId }))?.payload;

        form.update({ distributor });
      } else {
        form.update({ distributor: null });
      }
    })();
  }, [form.data.dealer?.representativeId]);
  //#endregion

  //#region PRODUCT
  function updateProduct(product: ContractProduct) {
    form.update({
      products: form.data.products?.map((p) => {
        if (p.id === product.id) {
          const updatedProduct = { ...p, ...product };
          updatedProduct.expiryDate =
            updatedProduct.termInMonths && updatedProduct.effectiveDate
              ? date(updatedProduct.effectiveDate, defaultServerDateTimeFormat)
                  .add(updatedProduct.termInMonths, 'months')
                  .tz('UTC')
                  .format(defaultServerDateTimeFormat)
              : null;
          return updatedProduct;
        } else return p;
      }),
    });
  }

  const [productWithPrices, setProductWithPrices] = useState<Array<ProductPricingInfo>>([]);
  const [productWithPricesLoading, setProductWithPricesLoading] = useState(false);

  useEffect(() => {
    if (form.data.dealer && form.data.distributor && form.data.vehicle) {
      (async function () {
        if (form.data.dealer && form.data.distributor && form.data.vehicle) {
          setProductWithPricesLoading(true);
          const result = await calculatePricesForContract({
            dealer: form.data.dealer as Model.IDealer,
            distributor: form.data.distributor,
            vehicle: form.data.vehicle as Model.IVehicle,
          });
          unstable_batchedUpdates(() => {
            setProductWithPrices(result);
            setProductWithPricesLoading(false);
          });
        }
      })();
    }
  }, [
    form.data.dealer,
    form.data.distributor,
    form.data.vehicle?.id,
    form.data.vehicle?.purchasePrice,
    form.data.vehicle?.posOdometerReading,
    form.data.vehicle?.purchaseState,
  ]);

  useEffect(() => {
    updateProduct({ id: form.data.product?.id, taxExempt: form.data.client?.taxExempt });
  }, [form.data.client?.taxExempt]);
  //#endregion

  //#region LENDER
  useEffect(() => {
    (async () => {
      if (form.data.vehicle?.lenderId) {
        const lender = (await Lender.find({ id: form.data.vehicle?.lenderId }))?.payload;
        form.update({ vehicle: { ...form.data?.vehicle, lender } });
      }
    })();
  }, [form.data.vehicle?.lenderId]);
  // #endregion

  //#region WARRANTY DATE CHECK
  function isWarrantyCovered(termInMonths: string, inServiceDate: string) {
    const currentDate = new Date();
    const inServiceFromDate = new Date(inServiceDate);

    const expirationDate = new Date(inServiceFromDate);
    expirationDate.setMonth(expirationDate.getMonth() + parseInt(termInMonths));
    expirationDate.setDate(expirationDate.getDate() + 1);

    return currentDate < expirationDate;
  }
  //#endregion

  const isSavingDone = contractStatuses?.every(
    ({ status }) => status === ContractSaveStatus.done || status === ContractSaveStatus.failed
  );

  async function sendContractConsent({
    contractId,
    consentRequestType,
  }: {
    contractId: number;
    consentRequestType: Model.Enum.ContractConsentRequestType;
  }) {
    const res = await contractSendConsentApi({ contractId, consentRequestType });
    if (res.status === HttpStatus.ok) addLog({ success: lang.emailSuccessfullySent });
    else addLog({ error: lang.saveError });
  }

  return {
    draftApi,
    contractStatuses,
    pageRouter,
    permissions,
    productWithPrices,
    productWithPricesLoading,
    form,
    isAllowed: permissions.CREATE_CONTRACT || permissions.EDIT_CONTRACT,
    activeTab,
    nextTab,
    prevTab,
    saveDraft,
    deleteDraft,
    next,
    openTab,
    reset,
    prev,
    updateProduct,
    searchedVin,
    setSearchedVin,
    fetchVehicles,
    vehicleDetails,
    tabs,
    isSavingDone,
    isWarrantyCovered,
    isLoading: contractFindApi.isExecuting || vehicleDetails.isExecuting || draftApi.isExecuting,
    sendContractConsent,
  };
}

export function useSalesNewContractProvider() {
  return useDataProvider<ReturnType<typeof useSalesNewContract>>();
}
