import DataMap from 'assets/components/dataMap/DataMap';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { set } from 'lodash';
import StatementEmailTemplateTags from 'module/management/components/statementEmailTemplate/StatementEmailTemplateTags';
import { useStatementProvider } from '../Statement.Hooks';

const StatementSendEmail = () => {
  const { lang } = getLocales();
  const { pageRouter, sendEmailApi, selected, statementEmailTemplate } = useStatementProvider();

  return (
    <ModalForm<Api.Core.Statement.SendEmail.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      dependencyArray={[selected]}
      default={{
        emailTemplate: {
          ...statementEmailTemplate,
          additionalRecipientEmails: [
            ...(selected?.dealer?.accountingEmails || []),
            ...(statementEmailTemplate?.additionalRecipientEmails || []),
          ],
        },
      }}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) =>
        sendEmailApi.execute({ statementId: Number(pageRouter.params?.id), emailTemplate: data?.emailTemplate })
      }
      validation={(data, errors) => {
        if (data.emailTemplate && !data.emailTemplate.name)
          set(errors, 'emailTemplate.name', `${lang.mustNotBeEmpty}: ${lang.name}`);
        if (data.emailTemplate && !data.emailTemplate.senderEmail)
          set(errors, 'emailTemplate.senderEmail', `${lang.mustNotBeEmpty}: ${lang.senderEmail}`);
        if (data.emailTemplate && !data.emailTemplate.subject)
          set(errors, 'emailTemplate.subject', `${lang.mustNotBeEmpty}: ${lang.senderName}`);
        if (data.emailTemplate && !data.emailTemplate.senderName)
          set(errors, 'emailTemplate.senderName', `${lang.mustNotBeEmpty}: ${lang.senderName}`);
        if (data.emailTemplate && !data.emailTemplate.htmlBody)
          set(errors, 'emailTemplate.htmlBody', `${lang.mustNotBeEmpty}: ${lang.htmlBody}`);
      }}
      render={({ form }) => {
        function updateEmailTemplate(updatedEmailTemplate: Partial<Model.IStatementEmailTemplate>) {
          form.merge({ emailTemplate: updatedEmailTemplate });
        }

        return (
          <FormWrapper>
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.emailTemplate?.name}
              error={form.errors.emailTemplate?.name}
              onChange={updateEmailTemplate}
              icon={<Icon class="if-quill-pen" />}
            />
            <InputWrapper>
              <TextInput
                name="senderName"
                label={lang.senderName}
                value={form.data.emailTemplate?.senderName}
                error={form.errors.emailTemplate?.senderName}
                onChange={updateEmailTemplate}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="senderEmail"
                label={lang.senderEmail}
                value={form.data.emailTemplate?.senderEmail}
                error={form.errors.emailTemplate?.senderEmail}
                onChange={updateEmailTemplate}
                icon={<Icon class="if-quill-pen" />}
              />
            </InputWrapper>
            <h2>{lang.additionalRecipientEmails}</h2>
            <DataMap
              data={[...(form.data.emailTemplate?.additionalRecipientEmails || []), '']}
              render={({ data: email, index }) => (
                <TextInput
                  key={index}
                  name="email"
                  label={lang.email}
                  value={email}
                  error={form.errors.emailTemplate?.additionalRecipientEmails}
                  onChange={({ email }) => {
                    let additionalRecipientEmails = [...(form.data.emailTemplate?.additionalRecipientEmails || [])];
                    additionalRecipientEmails?.splice(index, 1, email);
                    additionalRecipientEmails = additionalRecipientEmails?.filter((it) => it);
                    form.update({ emailTemplate: { ...form.data.emailTemplate, additionalRecipientEmails } });
                  }}
                  icon={<Icon class="if-quill-pen" />}
                />
              )}
            />
            <h2>{lang.email}</h2>
            <TextInput
              name="subject"
              label={lang.subject}
              value={form.data.emailTemplate?.subject}
              error={form.errors.emailTemplate?.subject}
              onChange={updateEmailTemplate}
              icon={<Icon class="if-quill-pen" />}
            />
            <RichTextInput
              name="htmlBody"
              label={lang.htmlBody}
              value={form.data.emailTemplate?.htmlBody}
              error={form.errors.emailTemplate?.htmlBody}
              onChange={updateEmailTemplate}
              icon={<Icon class="if-quill-pen" />}
              customActions={<StatementEmailTemplateTags />}
            />
          </FormWrapper>
        );
      }}
    />
  );
};

export default StatementSendEmail;
