import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { combineStrings } from 'assets/utils/data/String';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { keys } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Product from 'models/productManagement/product/Model.Product';
import Contract from 'models/sales/contract/Model.Contract';
import contractStatusFlowApi from 'models/sales/contract/statusFlow/Api.Contract.StatusFlow';
import { useMemo } from 'react';
import { allowedContractStatusesForClaimCreation } from '../claim/Claim.Hooks';
import { contractIndexRoute } from './Contract.Index';

export default function useContract() {
  const { permissions, user } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Contract.Params, Module.Claims.Contract.Query>({
    route: contractIndexRoute,
  });

  const hasAccess = permissions.EDIT_CLAIM || permissions.CLAIM_ADMIN || permissions.CREATE_CLAIM;
  const listApi = useApi({
    action: Contract.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
      dealerId: user?.dealers?.map((d) => d.id) ?? [],
      distributorId: user?.distributorId ?? undefined,
      statusCode: allowedContractStatusesForClaimCreation,
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !hasAccess,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const dealersApi = useApi({
    action: Dealer.list,
    wait: true,
    body: {
      id: user?.dealers?.map((d) => d.id) ?? undefined,
      distributorId: user?.distributorId ? [user?.distributorId] : undefined,
    },
  });
  const productsApi = useApi({
    action: Product.list,
    wait: true,
    body: {
      distributorId: user?.distributorId ?? undefined,
    },
  });

  const contractStatusInfoApi = useApi({ action: contractStatusFlowApi });
  const contractStatusInfo = contractStatusInfoApi.payload;

  const statuses = useMemo(() => {
    return keys(contractStatusInfo).map((status) => {
      const [primary, secondary, reason] = status.split('_');
      const mainStatus = combineStrings(' - ', primary, secondary);
      return {
        id: status,
        subtitle: mainStatus,
        title: reason ?? mainStatus,
      };
    });
  }, [contractStatusInfo]);

  return {
    hasAccess,
    pageRouter,
    listApi,
    selected,
    dealersApi,
    productsApi,
    permissions,
    statuses,
  };
}

export function useContractProvider() {
  return useDataProvider<ReturnType<typeof useContract>>();
}
