import Role from 'models/core/role/Model.Role';
import Model from 'models/Model';
import statementEmailTemplateListApi from './list/Api.StatementEmailTemplate.List';
import statementEmailTemplateSaveApi from './save/Api.StatementEmailTemplate.Save';
import statementEmailTemplateDeleteApi from './delete/Api.StatementEmailTemplate.Delete';

export default class StatementEmailTemplate
  extends Model<Model.IStatementEmailTemplate>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IStatementEmailTemplate>
{
  id: number;
  name: string;
  statementStatusTrigger: Model.Enum.StatementStatusCode;
  senderEmail: string;
  senderName: string;
  subject?: string;
  htmlBody: string;
  recipientsWithRole?: Role[];
  additionalRecipientEmails: string[] = [];

  constructor(
    data: Partial<Model.IStatementEmailTemplate> | Utils.FormData<Model.IStatementEmailTemplate>,
    language?: Locale.Languages
  ) {
    super(
      {
        recipientsWithRole: [Role],
      },
      language
    );
    this.update(data);
    if (!this.additionalRecipientEmails) this.additionalRecipientEmails = [];
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name,
      info: this.senderName,
      subtitle: this.senderEmail,
    };
  }
  static async list(body?: Api.Core.StatementEmailTemplate.List.IRequest) {
    const { payload, ...rest } = await statementEmailTemplateListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new StatementEmailTemplate(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await statementEmailTemplateSaveApi(this);
  }
  async delete() {
    return await statementEmailTemplateDeleteApi(this.id);
  }
}
