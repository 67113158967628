import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import Application from 'models/core/application/Model.Application';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import User from 'models/core/user/Model.User';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Model from 'models/Model';
import Contract from '../contract/Model.Contract';
import statementDeleteApi from './delete/Api.Statement.Delete';
import statementListApi from './list/Api.Statement.List';
import statementSaveApi from './save/Api.Statement.Save';

export default class Statement
  extends Model<Model.IStatement>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IStatement>
{
  id: number;
  status: Model.Enum.StatementStatusCode;
  statementNumber: number;
  totalAmountDueCAD: number;
  invoicingDate: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  representativeId: number;
  representative: User;
  dealerId: number;
  dealer: Dealer;
  contracts: Contract[];
  isEmailSent?: boolean;
  isPayed?: boolean;
  createdById?: number;
  createdBy?: User;
  modifiedById?: number;
  modifiedBy?: User;
  applicationId?: number;
  application?: Application;
  mediaFiles?: MediaFile[];

  constructor(data: Partial<Model.IStatement> | Utils.FormData<Model.IStatement>, language?: Locale.Languages) {
    super(
      {
        representative: User,
        dealer: Dealer,
        createdBy: User,
        modifiedBy: User,
        application: Application,
        contracts: [Contract],
        mediaFiles: [MediaFile],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.dealer?.name || this.id.toString(),
      subtitle: this.contracts
        .map((it) => `${it.appNumber}${it.revisionNumber ? `-R${it.revisionNumber}` : ''}`)
        .join(', '),
      info: combineStrings(
        ' | ',
        this.status,
        date(this.invoicingDate, defaultServerDateTimeFormat).format('MMMM YYYY')
      ),
    };
  }

  static async list(body?: Api.Core.Statement.List.IRequest) {
    const { payload, ...rest } = await statementListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Statement(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await statementSaveApi(this);
  }
  async delete() {
    return await statementDeleteApi(this.id);
  }
}
