import { combineStrings } from 'assets/utils/data/String';
import parseJwt from 'assets/utils/parsersAndValidation/Parsers';

export enum AuthPermission {
  createRole = 'CREATE_ROLE',
  editRole = 'EDIT_ROLE',
  deleteRole = 'DELETE_ROLE',
  viewPermission = 'VIEW_PERMISSION',
  createUser = 'CREATE_USER',
  editUser = 'EDIT_USER',
  deleteUser = 'DELETE_USER',
  resetPassword = 'RESET_PASSWORD',
  viewAccessLogs = 'VIEW_ACCESSLOGS',
  viewAuditLogs = 'VIEW_AUDITLOGS',
  viewCategory = 'VIEW_CATEGORY',
  viewApplication = 'VIEW_APPLICATION',
  claimAdmin = 'CLAIM_ADMIN',
  createClaim = 'CREATE_CLAIM',
  createSimpleClaim = 'CREATE_SIMPLE_CLAIM',
  editClaim = 'EDIT_CLAIM',
  editClaimCustomerInfo = 'EDIT_CLAIMCUSTOMERINFO',
  deleteClaim = 'DELETE_CLAIM',
  deleteClaimJob = 'DELETE_CLAIMJOB',
  createClaimType = 'CREATE_CLAIMTYPE',
  editClaimType = 'EDIT_CLAIMTYPE',
  deleteClaimType = 'DELETE_CLAIMTYPE',
  createLossCode = 'CREATE_LOSSCODE',
  editLossCode = 'EDIT_LOSSCODE',
  deleteLossCode = 'DELETE_LOSSCODE',
  importLossCode = 'IMPORT_LOSSCODE',
  createLossCodeCategory = 'CREATE_LOSSCODECATEGORY',
  editLossCodeCategory = 'EDIT_LOSSCODECATEGORY',
  deleteLossCodeCategory = 'DELETE_LOSSCODECATEGORY',
  createProduct = 'CREATE_PRODUCT',
  editProduct = 'EDIT_PRODUCT',
  deleteProduct = 'DELETE_PRODUCT',
  createPayee = 'CREATE_PAYEE',
  editPayee = 'EDIT_PAYEE',
  deletePayee = 'DELETE_PAYEE',
  internalComment = 'INTERNAL_COMMENT',
  externalComment = 'EXTERNAL_COMMENT',
  createContract = 'CREATE_CONTRACT',
  uploadDocumentContract = 'UPLOAD_DOCUMENTS_CONTRACT',
  editContract = 'EDIT_CONTRACT',
  editContractUnrestricted = 'EDIT_CONTRACT_UNRESTRICTED',
  vertaforeSyncContract = 'VERTAFORE_SYNC_CONTRACT',
  deleteContract = 'DELETE_CONTRACT',
  changeContractCreator = 'CHANGE_CONTRACT_CREATOR',
  createEmailTemplate = 'CREATE_EMAILTEMPLATE',
  editEmailTemplate = 'EDIT_EMAILTEMPLATE',
  deleteEmailTemplate = 'DELETE_EMAILTEMPLATE',

  createVehicleModel = 'CREATE_VEHICLEMODEL',
  editVehicleModel = 'EDIT_VEHICLEMODEL',
  deleteVehicleModel = 'DELETE_VEHICLEMODEL',
  createVehicleMake = 'CREATE_VEHICLEMAKE',
  editVehicleMake = 'EDIT_VEHICLEMAKE',
  deleteVehicleMake = 'DELETE_VEHICLEMAKE',
  importVehicleMakeFromExcel = 'IMPORT_VEHICLEMAKE_FROM_EXCEL',
  createVehicleClass = 'CREATE_VEHICLECLASS',
  editVehicleClass = 'EDIT_VEHICLECLASS',
  deleteVehicleClass = 'DELETE_VEHICLECLASS',
  createTier = 'CREATE_TIER',
  editTier = 'EDIT_TIER',
  deleteTier = 'DELETE_TIER',
  createLender = 'CREATE_LENDER',
  editLender = 'EDIT_LENDER',
  deleteLender = 'DELETE_LENDER',
  createInsurer = 'CREATE_INSURER',
  editInsurer = 'EDIT_INSURER',
  deleteInsurer = 'DELETE_INSURER',
  createPricingPlan = 'CREATE_PRODUCTPRICINGPLAN',
  editPricingPlan = 'EDIT_PRODUCTPRICINGPLAN',
  deletePricingPlan = 'DELETE_PRODUCTPRICINGPLAN',

  createCustomPricePoint = 'CREATE_CUSTOMPRICEPOINT',
  editCustomPricePoint = 'EDIT_CUSTOMPRICEPOINT',
  deleteCustomPricePoint = 'DELETE_CUSTOMPRICEPOINT',
  createNonWarrantyPricePoint = 'CREATE_NONWARRANTYPRICEPOINT',
  editNonWarrantyPricePoint = 'EDIT_NONWARRANTYPRICEPOINT',
  deleteNonWarrantyPricePoint = 'DELETE_NONWARRANTYPRICEPOINT',
  createPricingLimitTemplate = 'CREATE_PRICINGLIMITTEMPLATE',
  editPricingLimitTemplate = 'EDIT_PRICINGLIMITTEMPLATE',
  deletePricingLimitTemplate = 'DELETE_PRICINGLIMITTEMPLATE',
  createPricingDistributionTemplate = 'CREATE_PRICINGDISTRIBUTIONTEMPLATE',
  editPricingDistributionTemplate = 'EDIT_PRICINGDISTRIBUTIONTEMPLATE',
  deletePricingDistributionTemplate = 'DELETE_PRICINGDISTRIBUTIONTEMPLATE',
  createProductProductTierPricePoint = 'CREATE_PRODUCTTIERPRICEPOINT',
  editProductProductTierPricePoint = 'EDIT_PRODUCTTIERPRICEPOINT',
  deleteProductProductTierPricePoint = 'DELETE_PRODUCTTIERPRICEPOINT',

  deleteDistributor = 'DELETE_DISTRIBUTOR',
  editDistributor = 'EDIT_DISTRIBUTOR',
  createDistributor = 'CREATE_DISTRIBUTOR',
  deleteDealer = 'DELETE_DEALER',
  editDealer = 'EDIT_DEALER',
  createDealer = 'CREATE_DEALER',
  pricingLimitsDealer = 'PRICINGLIMITS_DEALER',
  deleteDealerGroup = 'DELETE_DEALERGROUP',
  editDealerGroup = 'EDIT_DEALERGROUP',
  createDealerGroup = 'CREATE_DEALERGROUP',

  createStatement = 'CREATE_STATEMENT',
  editStatement = 'EDIT_STATEMENT',
  deleteStatement = 'DELETE_STATEMENT',
  updateStatementStatus = 'UPDATE_STATEMENT_STATUS',
  updateContractStatus = 'UPDATE_CONTRACT_STATUS',

  createStatementSummary = 'CREATE_STATEMENTSUMMARY',
  deleteStatementSummary = 'DELETE_STATEMENTSUMMARY',
}
export type VerifyPermission = Utils.VerifyExtends<Auth.Permission, Utils.ValueOf<typeof AuthPermission>>;

const authTokenSessionKey = 'token';
export function getAuthToken() {
  return localStorage.getItem(authTokenSessionKey);
}
export function setAuthToken(token?: string) {
  if (!token) localStorage.removeItem(authTokenSessionKey);
  else localStorage.setItem(authTokenSessionKey, token);
}
export function unpackToken(data: string) {
  const tokenUnpacked = parseJwt<Api.TokenUnpacked>(data);
  return !tokenUnpacked
    ? {}
    : {
        token: data,
        userId: parseInt(tokenUnpacked.UserId),
        expirationTimestamp: tokenUnpacked.exp,
        displayname: combineStrings(' ', tokenUnpacked.family_name, tokenUnpacked.given_name),
        familyName: tokenUnpacked.family_name,
        givenName: tokenUnpacked.given_name,
        language: tokenUnpacked.ContentLanguage,
        email:
          tokenUnpacked.email || tokenUnpacked['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
        permissions: tokenUnpacked.Premission
          ? Array.isArray(tokenUnpacked.Premission)
            ? tokenUnpacked.Premission.reduce((prev, curr) => ({ ...prev, [curr]: true }), {})
            : { [tokenUnpacked.Premission]: true }
          : {},
      };
}
